import DupontLogger from "@utils/DupontLogger";

import { UF_MODULE_PER_RACK_RANGE } from "../../constants/UFConstants";

import { getNModulesTrainTarget, getStandByUnits } from "./UFConfigFormulasHelper";

const Logger = DupontLogger("UFConfigFormulas");
const MAXIMUM_ROWS = 9;

//These values are as mentioned in 139223
const ONLINE_MODULES_TARGET_TOLERANCE_FACTOR = {
  Module_Target_20: 1.1,
  Module_Target_10: 1.2,
  Default: 6,
};

const getEvenNumber = value => {
  const ceilValue = Math.ceil(value);
  return ceilValue % 2 === 0 ? ceilValue : ceilValue + 1;
};

const calculateCEB = (onlineTrains, bwFilterPerMax) => Math.floor(Math.max(1, onlineTrains / bwFilterPerMax + 0.99));

/**
 * Dev Ops: #139223
 *
 * Determines the tolerance factor key based on the module target value.
 *
 * @param {number} moduleTarget - The target value for the module.
 * @returns {string} The corresponding tolerance factor key.
 */
const getTrackToleranceFactor = moduleTarget => {
  let toleranceFactorKey;
  if (moduleTarget >= 20) {
    toleranceFactorKey = "Module_Target_20";
  } else if (moduleTarget >= 10) {
    toleranceFactorKey = "Module_Target_10";
  } else {
    toleranceFactorKey = "Default";
  }

  return ONLINE_MODULES_TARGET_TOLERANCE_FACTOR[toleranceFactorKey];
};

/*
 *  ================================================ Case 1 ================================================================
 *   Non-rack configurations (Flag_IP_Design, Flag_TR_Design, Flag_MR_Design all false)
 *   Constant Flux operation (no standby)
 *
 *   Constant Flux operation (no standby)
 *    •	Compute N_modules_target
 *    •	Compute N_Online_Trains_Minimum = INT(N_modules_target/200) + 1 (will be 1 unless N_modules_target > 200)
 *    •	Loop over N_Online_Trains = N_Online_Trains_Minimum to N_Online_Trains_Minimum + 10
 *        o	N_Standby_Trains = 0
 *        o	N_Total_Trains = N_Online_Trains + N_Standby_Trains
 *        o	N_Modules_Train_Target = ROUNDUP(N_modules_target/ Online_Trains, 0) [rounds up to next integer]
 *        o	N_Modules_Train_Adjusted = EVEN(N_Modules_Train_Target) [rounds up to next even integer]
 *        o	N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted
 *        o	N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted
 *        o	If N_Modules_Train_Adjusted <= 2 then exit loop
 *
 * ================================================ Case 2 ================================================================
 *
 *   Case 2
 *     Non-rack configurations (Flag_IP_Design, Flag_TR_Design, Flag_MR_Design all false)
 *     Constant Flow operation (with standby)
 *       •	Compute N_modules_target
 *       •	Compute N_Online_Trains_Minimum = INT(N_modules_target/200) + 1  [will be 1 unless N_modules_target > 200)]
 *       •	Loop over N_Online_Trains = N_Online_Trains_Minimum to N_Online_Trains_Minimum + 10
 *          o	N_Standby_Trains = MAX(1, INT(N_Online_Trains / (N_BW_per_filter_max+1) + 0.99))
 *          o	N_Total_Trains = N_Online_Trains + N_Standby_Trains
 *          o	N_Modules_Train_Target = ROUNDUP(N_modules_target * t_filter_module_day/(1440 - t_MIT_module_day) / Online_Trains, 0) [rounds up to next integer]
 *          o	N_Modules_Train_Adjusted = EVEN(N_Modules_Train_Target) [rounds up to next even integer]
 *          o	N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted
 *          o	N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted
 *          o	If N_Modules_Train_Adjusted <= 2 then exit loop
 *
 *     t_filter_module_day= 1261.95917594545
 *     N_Modules_target=204
 *     N_BW_per_filter_max=4
 */
export const calculateUFConfigWithNoRack = (calcEngineData, offlinetimepertrain, withStandBy, isInge) => {
  Logger.log("Calculating calculateUFConfigWithNoRack - calcEngineData, withStandBy: ", calcEngineData, withStandBy);
  const configurations = [];
  if (calcEngineData) {
    const N_Modules_Target = calcEngineData.n_modules_target;
    // To avoid recommed table showing modules/unit > 120 , out of max range of MODULE_PER_UNITS_INGE_RANGE in ufconstant
    const N_Modules_Targe_Factor = isInge ? 120 : 200;
    const N_Online_Trains_Minimum = Math.max(1, Math.floor(N_Modules_Target / N_Modules_Targe_Factor) + 1);
    const N_BW_per_filter_max = Number(calcEngineData.n_BW_per_filter_max);

    for (
      let N_Online_Trains = N_Online_Trains_Minimum;
      N_Online_Trains <= N_Online_Trains_Minimum + MAXIMUM_ROWS;
      N_Online_Trains++
    ) {
      const N_Standby_Trains = withStandBy ? calculateCEB(N_Online_Trains, N_BW_per_filter_max + 1) : 0;
      const N_Total_Trains = N_Online_Trains + N_Standby_Trains;
      const t_filter_module_day = Number(calcEngineData.t_filter_module_day);
      const offlineTimePerTrain = offlinetimepertrain ? Number(offlinetimepertrain) : 0;

      const N_Modules_Train_Target = withStandBy
        ? (N_Modules_Target * t_filter_module_day) / (1440 - offlineTimePerTrain) / N_Online_Trains
        : Math.ceil(N_Modules_Target / N_Online_Trains); // [rounds up to next integer]
      const N_Modules_Train_Adjusted = getEvenNumber(N_Modules_Train_Target);

      if (N_Modules_Train_Adjusted <= 2) {
        Logger.log("calculateUfConfig - Exiting From Loop, N_Modules_Train_Adjusted <= 2", N_Modules_Train_Adjusted);
        break;
      }
      const N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted;
      const N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted;
      const maxOfflineBW_CEB = withStandBy ? N_Standby_Trains : calculateCEB(N_Online_Trains, N_BW_per_filter_max + 2);

      configurations.push({
        options: N_Online_Trains - N_Online_Trains_Minimum + 1,
        onlineUnits: N_Online_Trains,
        standByUnits: N_Standby_Trains,
        totalUnits: N_Total_Trains,
        maxOfflineBW_CEB,
        modulesPerRack: "-",
        racksPerUnit: "-",
        modulesPerUnit: N_Modules_Train_Adjusted,
        onlineModules: N_Online_Modules,
        totalModules: N_Total_Modules,
      });
    }
  }

  return configurations;
};

export const calculateDuPontUFConfigWithRack = (calcEngineData, offlinetimepertrain, withStandBy) => {
  const configurations = [];
  const IP_Skid_Size_min = UF_MODULE_PER_RACK_RANGE.min;
  const IP_Skid_Size_max = UF_MODULE_PER_RACK_RANGE.max;
  let options = 0;

  //const N_Modules_Target_Even = getEvenNumber(N_Modules_Target);
  const N_Modules_Target = Math.max(1, calcEngineData.n_modules_target); // for very low flow (extreme cases) like 1 m3/h flux is negative , and n_modules_target is less than 1
  const N_Online_Trains_Minimum = Math.round(N_Modules_Target / 200 + 1);
  const N_Online_Trains_Maximum = N_Online_Trains_Minimum + MAXIMUM_ROWS;
  const T_Filter_Module_Day = Number(calcEngineData.t_filter_module_day);
  const T_MIT_module_day = Number(offlinetimepertrain);

  const N_BW_per_filter_max = Number(calcEngineData.n_BW_per_filter_max);

  Logger.log(
    `calculateDuPontUFConfigWithRack 
    withStandBy: ${withStandBy}
    IP_Skid_Size_min: ${IP_Skid_Size_min} 
    IP_Skid_Size_max: ${IP_Skid_Size_max} 
    N_Modules_Target: ${N_Modules_Target}
    N_Online_Trains_Minimum(${N_Modules_Target} / 200 + 1): ${N_Online_Trains_Minimum}
    N_Online_Trains_Maximum: ${N_Online_Trains_Maximum} 
    N_BW_per_filter_max: ${N_BW_per_filter_max}
    T_MIT_module_day: ${T_MIT_module_day}
    T_Filter_Module_Day: ${T_Filter_Module_Day}
    calcEngineData: `,
    calcEngineData,
  );

  for (let N_Online_Trains = N_Online_Trains_Minimum; N_Online_Trains <= N_Online_Trains_Maximum; N_Online_Trains++) {
    const N_Standby_Trains = getStandByUnits(withStandBy, N_Online_Trains, N_BW_per_filter_max);
    const N_Total_Trains = N_Online_Trains + N_Standby_Trains;
    const N_Modules_Train_Target = getNModulesTrainTarget(
      withStandBy,
      N_Modules_Target,
      T_Filter_Module_Day,
      T_MIT_module_day,
      N_Online_Trains,
    );

    for (let IP_Mod_Skid = IP_Skid_Size_max; IP_Mod_Skid >= IP_Skid_Size_min; IP_Mod_Skid -= 2) {
      options++;
      const N_IP_Skids = Math.ceil(N_Modules_Train_Target / IP_Mod_Skid, 0);
      const N_Modules_Train_Adjusted = N_IP_Skids * IP_Mod_Skid;
      const N_Online_Modules = N_Online_Trains * N_Modules_Train_Adjusted;
      const N_Total_Modules = N_Total_Trains * N_Modules_Train_Adjusted;

      const toleranceFactor = getTrackToleranceFactor(N_Modules_Target);
      Logger.log("N_Modules_Target => toleranceFactor: ", N_Modules_Target, toleranceFactor);

      if (N_Online_Modules > toleranceFactor * N_Modules_Target) continue;

      const isMultiplierExist = configurations.find(
        item =>
          item.onlineUnits === N_Online_Trains &&
          item.modulesPerRack !== IP_Mod_Skid &&
          (item.modulesPerRack % IP_Mod_Skid === 0 || (item.modulesPerRack === 18 && IP_Mod_Skid === 12)), // Don't show 12 if, 18 is already there
      );
      if (isMultiplierExist) continue;

      configurations.push({
        options,
        onlineUnits: N_Online_Trains,
        standByUnits: N_Standby_Trains,
        totalUnits: N_Online_Trains + N_Standby_Trains,
        maxOfflineBW_CEB: Math.max(1, Math.floor(N_Online_Trains / (N_BW_per_filter_max + 1) + 0.99)), // 0.99 is added to round up the value to next Integer
        modulesPerRack: IP_Mod_Skid,
        racksPerUnit: N_IP_Skids,
        modulesPerUnit: N_Modules_Train_Adjusted,
        onlineModules: N_Online_Modules,
        totalModules: N_Total_Modules,
      });
    }
    if (N_Modules_Train_Target <= IP_Skid_Size_min) break;
  }

  Logger.log("calculateDuPontUFConfigWithRack - calculated configurations: ", configurations);
  return configurations;
};
