import API_URLS from "@constants/api.urls";

import { deleteRequest, getRequest, postRequest, putRequest } from "../baseApi";

export const createFolder = data => postRequest(API_URLS.folder, data);

export const updateFolder = data => putRequest(API_URLS.folder, data);

export const deleteFolder = data => deleteRequest(API_URLS.deleteFolder, data);

export const getFolderList = () => getRequest(API_URLS.folderList);

export const restoreFolder = data => putRequest(API_URLS.restoreFolder, data);

export const permanentDeleteFolder = data => deleteRequest(API_URLS.permanentDeleteFolder, data);

export const moveFolder = data => putRequest(API_URLS.projectFolderMapping, data);
