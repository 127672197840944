import React from "react";

import { combineClassNames } from "@utils/appUtils";

import loader from "../../../assets/images/loader.gif";

import "./index.css";

const CircularLoader = ({ size = "40px", className }) => (
  <div className={combineClassNames("loader", className)} data-test-id='wp-loader'>
    <img src={loader} alt='Loading...' style={{ width: size, height: size }} />
  </div>
);

export default CircularLoader;
