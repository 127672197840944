import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { convertUptoDigits } from "@utils/appUtils";

import TextElement from "../UFDiagramsUtils/TextElement";
import { DESIGN_INTERVEL_FIELDS, DESIGN_INTERVEL_LINES } from "../UFDiagramsUtils/UFDiagramConstants";

const UFDiagramDesignCycleIntervals = ({ isInge }) => {
  const { data: UFData, defaultInputRangeConfig } = useSelector(state => state.UFStore);

  const xyPosition = useMemo(() => (isInge ? { x: 5000, y: 4340 } : { x: 5000, y: 4500 }), [isInge]);

  const getValue = useCallback(key => {
    const value = convertUptoDigits(UFData[key], 0);
    return `${value} ${defaultInputRangeConfig[key]?.uom || ""}`;
  }, []);

  return (
    <>
      <g id='Group 1363'>
        <rect
          id='Rectangle 964_2'
          width='4600'
          height='500'
          fill='#F9F9F9'
          stroke='#E1E1E1'
          strokeWidth='10'
          {...xyPosition}
        />
        {DESIGN_INTERVEL_LINES.map((line, index) => (
          <line
            key={`table_line${index}`}
            x1={xyPosition.x + line.x1Diff}
            y1={xyPosition.y + line.y1Diff}
            x2={xyPosition.x + line.x1Diff + line.x2Diff}
            y2={xyPosition.y + line.y1Diff + line.y2Diff}
            stroke='#E1E1E1'
            strokeWidth='10'
          />
        ))}
        {DESIGN_INTERVEL_FIELDS.map((item, index) => (
          <g key={index}>
            <TextElement fontWeight='bold'>
              <tspan x={xyPosition.x + item.x} y={xyPosition.y + item.y}>
                {item.label}
              </tspan>
            </TextElement>
            <TextElement>
              <tspan x={xyPosition.x + item.x + item.xDiff} y={xyPosition.y + item.y}>
                {getValue(item.key)}
              </tspan>
            </TextElement>
          </g>
        ))}
      </g>
    </>
  );
};

export default UFDiagramDesignCycleIntervals;
