import React from "react";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import Footer from "@common/footer";

import "./WPHeaderFooterPage.scss";

const WPHeaderFooterPage = ({ children }) => (
  <div className='header-footer-page-container'>
    <div className='brand-logo-container'>
      <div className='app-max-width mx-auto w-100'>
        <WaveProBrandLogo />
      </div>
    </div>
    <div className='p-32 w-100'>
      <div className='main-content app-max-width'>{children}</div>
    </div>
    <Footer />
  </div>
);

export default WPHeaderFooterPage;
