export const HomeStrings = {
  homeHeadline: "Quickly access your 10 most recently viewed projects",
  allProjectHeadline: "Browse every project created across all technologies",
  favoriteProjectHeadline: "Easily access projects you've marked as favorites",
  deletedProjectHeadline: "Restore or permanently delete projects and folders within 30 days",
  importProjectHeadline: "Find all projects you've imported into WAVE PRO",
  exampleProjectHeadline: "Explore examples to jump-start your next project",
  sharedProjectsHeadline: "Changes in shared projects will reflect for all collaborators",
  selectFoldersHeadline: "Select the folders to see the projects under them",
  allFolders: "All Folders",
  dateCreated: "Date Created",
  lastModified: "Last Modified",
  ascending: "Ascending",
  descending: "Descending",
  searchProjects: "Search Projects",
  searchFolders: "Search Folders",
  searchDeleted: "Search Deleted",
  recentProjects: "Recent Projects",
  searchFavorites: "Search Favorites",
  searchRecents: "Search Recents",
  searchExamples: "Search Examples",
  searchImported: "Search Imported",
  sharedWithMe: "Shared with Me",
  sharedByMe: "Shared by Me",
  youAreOwner: "You are the owner",
  ownerDetails: "Owner: ${email}",
  lockedByYouMsg: "Locked by You (Other collaborator can't access it)",
  lockedByCollaboratos: "Currently unlocked (Anyone with access can open and edit)"
};

export const HomeSideMenuStrings = {
  home: "Home",
  allProjects: "All Projects",
  favouriteProjects: "Favorite Projects",
  importtedProjects: "Imported Projects",
  sharedProjects: "Shared Projects",
  exampleProjects: "Example Projects",
  deletedProjects: "Deleted",
  folders: "Folders",
  folderMoved: "Project 'PROJECT_NAME' has been moved to 'FOLDER_NAME'",
};

