export const UF_FIELDS_MAPPING = {
  filtrateFlux: "Filtrate Flux",
  backwashFlux: "Backwash Flux",
  cEBFlux: "CEB Flux",
  forwardFlushFlow: "Forward Flush Flow",
  airFlow: "Air Flow",
  recycleFlowRate: "CIP Recycle Flow Rate",
  aerationAirFlow: "Aeration Air Flow",
  recycleFlowRate_MiniCIP: "Mini-CIP Recycle Flow Rate",
  miniCIP: "mCIP Interval",
  disinfectionCEB: "Disinfection CEB Interval",
  onlineTrains: "Online Trains",
  redundantStandbyTrains: "Standby Trains",
  modulesPerTrain: "Modules/Train",
  acidCEB_Filtration: "Acid CEB TMP Increase",
  cIP_Filtration: "CIP TMP increase",
  alkaliCEB_Filtration: "Alkali CEB TMP increase",
  strainerRecovery: "Strainer Recovery",
  strainerSize: "Strainer Size",
  tocRejection: "TOC Rejection",
  cEBTemperature: "CEB Temperature",
  filteratePressure: "Filtrate Pressure",
  maxAirScourPressure: "Average Air Scour Pressure",
  nonIntegraPacTrainPresDrop: "Non-IntegraPac Filtration Pressure Drop",
  integraPacFiltrationPreDrop: "IntegraPac Filtration Pressure Drop",
  backwashPipingPreDrop: "Backwash Piping Pressure Drop",
  cIPPipingPreDrop: "CIP Piping Pressure Drop",
  pLCPowerReqPertrain: "PLC Power Requirement per Train",
  volvePowerReqPerTrain: "Valve Power Requirements per Valve",
  valvesPerTrain: "Valves per train",
  typicalWaitDuration_Dupont: "Typical Wait Duration_Dupont",
  typicalPumpRamp_Dupont: "Typical Pump Ramp_Dupont",
  typicalWaitDuration_Inge: "Typical Wait Duration_Inge",
  typicalPumpRamp_Inge: "Typical Pump Ramp_Inge",
  chemicalStorageTime: "Chemical Storage Time (days)",
  bWTankRefillRate: "Backwash Only Tank Refill Rate",
  filterateTank: "Tank Size Factor (BW+Filtrate Tank)",
  bWTank: "Tank Size Factor (BW only Tank)",
  cIPTank: "Tank Size Factor (CIP Tank)",
  ceb_AirScour: "t_AS",
  backWash1_backWash: "t_BW1&2",
  backWash2_backWash: "t_BW1&2",
  forwardFlush_backWash: "t_FF",
  drain_backWash: "t_drain",
  backwash_AirScour: "t_AS",
  backWash1_CEB: "t_CEB1&2",
  backWash2_CEB: "t_CEB1&2",
  chemicalSoakingDuration_CEB: "CEB Soak Time",
  drain: "t_drain",
  forwardFlush: "t_FF",
  skidsPerTrain: "IntegraPac Skids/Train",
  modulesPerSkid: "IntegraPac Modules/Skid",
  chemicalSoakingDuration_CIP: "CIP Soak",
  chemicalSoakingDuration_MiniCIP: "mCIP Soak",
  recycleDuration: "CIP Recycle",
  recycleDuration_MiniCIP: "mCIP Recycle",
  heatingStepDuration: "CIP Heating Step",
  cIPRinseSoakCycle: "CIP Rinse Soak Cycle",
};
