import React from "react";

const ProjectLockOwnerIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M13 7.25H11.9375V3.75C11.9375 2.64531 11.0422 1.75 9.9375 1.75H6.0625C4.95781 1.75 4.0625 2.64531 4.0625 3.75V7.25H3C2.72344 7.25 2.5 7.47344 2.5 7.75V13.75C2.5 14.0266 2.72344 14.25 3 14.25H13C13.2766 14.25 13.5 14.0266 13.5 13.75V7.75C13.5 7.47344 13.2766 7.25 13 7.25ZM8.4375 10.9531V11.7813C8.4375 11.85 8.38125 11.9063 8.3125 11.9063H7.6875C7.61875 11.9063 7.5625 11.85 7.5625 11.7813V10.9531C7.43352 10.8605 7.33725 10.7294 7.28755 10.5786C7.23784 10.4278 7.23727 10.2651 7.28591 10.114C7.33455 9.96282 7.42989 9.831 7.55821 9.73749C7.68654 9.64398 7.84122 9.5936 8 9.5936C8.15878 9.5936 8.31346 9.64398 8.44179 9.73749C8.57011 9.831 8.66545 9.96282 8.71409 10.114C8.76273 10.2651 8.76216 10.4278 8.71245 10.5786C8.66275 10.7294 8.56648 10.8605 8.4375 10.9531V10.9531ZM10.8125 7.25H5.1875V3.75C5.1875 3.26719 5.57969 2.875 6.0625 2.875H9.9375C10.4203 2.875 10.8125 3.26719 10.8125 3.75V7.25Z'
      fill='black'
      fillOpacity='0.85'
    />
  </svg>
);

export default ProjectLockOwnerIcon;
