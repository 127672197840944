import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "src/api";

import API_URLS from "@constants/api.urls";
import { UNIT_TYPES } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import { useUFInputRanges } from "@hooks/useUFInputRanges";
import useUnitConversion from "@hooks/useUnitConversion";

import {
  calculateSum,
  changeChemicalFormat,
  getDisabledUFTech as getDisabledUFFlow,
  isValuesZero,
} from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import {
  INGE_MODULE_TR_OPTIONS_RANGE,
  INGE_MODULE_TR_RANGES,
  TYPICAL_PUMP_RAMP,
  TYPICAL_WAIT_DURATION,
  UF_MAX_PUMP_PRESSURES,
  UF_MODULE_PER_RACK_RANGE,
  UF_SPECIAL_FEATURE,
  WATER_TYPES_OFFLINE_TRAINS,
} from "@features/feedwater/uf/constants/UFConstants";
import {
  calculateDuPontUFConfigWithRack,
  calculateUFConfigWithNoRack,
} from "@features/feedwater/uf/UFConfiguration/UFConfigFormulas/UFConfigDupontFormulas";
import { calculateIngeUFConfigWithRack } from "@features/feedwater/uf/UFConfiguration/UFConfigFormulas/UFConfigIngeFormulas";
import { calculateUFFields } from "@features/feedwater/uf/UFConfiguration/UFHelper";
import { handleCalcEngineResponse, updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { formatReportPayload, isIngeSelected } from "@features/feedwater/uf/ufUtils";

import {
  COAGULANT_CHEMICALS,
  DURATION_FIELDS_MAPPING,
  MODULE_DETAILS_MAPPING,
  MODULE_DETAILS_MAPPING_UPPER_CASE,
  STANDARD_FIELDS_MAPPING,
  STREAMDATA_FIELDS_MAPPING,
  UF_CHEMICALS_TYPES_SYMBOL,
  UF_CONFIG_COMMON_PAYLOAD,
  UF_PUMP_LIST,
} from "./useUFConfigConstants";
import { getChemicalDetail, getFeedwaterParams, getUFConfigChemicalParams } from "./useUFConfigHelper";

const useUFConfig = () => {
  const Logger = DupontLogger("hooks.useUFConfig");

  const dispatch = useDispatch();

  const { getModuleBasedInputRangeConfigs, getDefaultUFConfiguration, getUFFieldsDefaultValues } = useUFInputRanges();
  const { unitConversionByName, convertUnitNumber, activeUnits, convertToMetric } = useUnitConversion();
  const { getSelectedChemicalByType, isValueInPh } = useUFChemicalsHandler();

  const {
    ufModules,
    ingeTROption,
    data: UFData,
    activeUFModule,
    ufModuleFlowVM,
    isCustomConfigAvail,
    ufModulePressureRatingVM,
    isRecommendationCalucalting,
    ufChemicalAdjustment,
    ufCompany,
    calcEngineData,
  } = useSelector(state => state.UFStore);

  const { data, projectConfig, projectChemicalCosts } = useSelector(state => state.projectInfo);

  const { projectID } = data;
  const { pumpCofig, unitConfig } = projectConfig;
  const { chemicalListById, chemicalListByName, operationCost } = projectChemicalCosts || {};
  const caseID = useSelector(state => state.projectInfo.activeCase?.caseID);

  const { unitTypeFlux } = useSelector(state => state.GUnitConversion);
  const { selectedEndNode, feedFlowRate, productFlowRate } = useSelector(state => state.processDiagramSlice);
  const StreamStoreData = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0],
  );

  const convertUnitString = (value, sourceUnit, targetUnit) => {
    const convertedVal = convertUnitNumber(value, sourceUnit, targetUnit)?.toString();
    return convertedVal;
  };

  const disabledUFFlow = useMemo(() => getDisabledUFFlow(UFData.ufSpecialFeatureID), [UFData.ufSpecialFeatureID]);

  const isMCIPDisabled = useMemo(() => disabledUFFlow === "mCIP", [disabledUFFlow]);
  const isCEBDisabled = useMemo(() => disabledUFFlow === "CEB", [disabledUFFlow]);

  const activePressureUnit = activeUnits[UNIT_TYPES.PRESSURE];

  const feedPumpMaxPressure = useMemo(() => {
    const { p_Filtrate = 0, delta_P_Piping_T_min = 0 } = calcEngineData;
    const maxTMP = UF_MAX_PUMP_PRESSURES.feed[ufCompany];
    return calculateSum([p_Filtrate, delta_P_Piping_T_min, maxTMP]);
  }, [calcEngineData, ufCompany]);

  const bwPumpMaxPressure = useMemo(() => {
    const { backwashPipingPreDrop } = UFData;
    const bwPipDrop = convertToMetric(backwashPipingPreDrop, UNIT_TYPES.PRESSURE);
    const maxTMP = UF_MAX_PUMP_PRESSURES.bw[ufCompany];
    return calculateSum([bwPipDrop, maxTMP]);
  }, [UFData?.backwashPipingPreDrop, ufCompany, activePressureUnit]);

  const cipPumpMaxPressure = useMemo(() => {
    const { cIPPipingPreDrop } = UFData;
    const cipPipPreDrop = convertToMetric(cIPPipingPreDrop, UNIT_TYPES.PRESSURE);
    const maxTMP = Math.max(1.5, 2 * cipPipPreDrop);
    const value = Math.min(maxTMP, 3); // same value for both inge and dupont
    return value;
  }, [UFData?.cIPPipingPreDrop, ufCompany, activePressureUnit]);

  const airScourMaxPressure = useMemo(() => UF_MAX_PUMP_PRESSURES.airScour[ufCompany]);

  // returns values in metric
  const ufMaxPumpPressureValues = {
    feed: feedPumpMaxPressure,
    bw: bwPumpMaxPressure,
    cip: cipPumpMaxPressure,
    airScour: airScourMaxPressure,
  };

  const getCalculateData = () => {
    const getDatByKey = key => (UFData[key] ? Number(UFData[key]) / 60 : 0);

    const t_drain = getDatByKey("drain_backWash");
    const t_bw2 = getDatByKey("backWash2_backWash");
    const t_bw1 = getDatByKey("backWash1_backWash");
    const t_ff = getDatByKey("forwardFlush_backWash");

    const t_BW_wait_module_cycle_adj = getDatByKey(TYPICAL_WAIT_DURATION[UFData.pUFTechnologyID]);
    const t_BW_ramp_module_cycle_adj = getDatByKey(TYPICAL_PUMP_RAMP[UFData.pUFTechnologyID]);
    const t_BW_Valves_module_cycle_adj = getDatByKey("valveOpenCloseDuration");

    const commonCalculation = t_drain + t_bw1 + t_bw2 + t_ff;
    getDatByKey("backWash2_backWash") + // t_bw2
      getDatByKey("backWash1_backWash") + // t_bw1
      getDatByKey("forwardFlush_backWash"); // t_ff

    let result = 0;
    if (UFData.uFBWProtocolID == 1) {
      result =
        commonCalculation +
        3 * getDatByKey("valveOpenCloseDuration") +
        4 * getDatByKey(TYPICAL_WAIT_DURATION[UFData.pUFTechnologyID]) +
        getDatByKey(TYPICAL_PUMP_RAMP[UFData.pUFTechnologyID]);
    } else if (isIngeSelected(UFData.pUFTechnologyID)) {
      const pumpMultiplier = t_ff > 0 ? 4 : 2;
      const valveMultiplier = t_ff > 0 ? 3 : 2;
      result =
        commonCalculation +
        valveMultiplier * t_BW_Valves_module_cycle_adj +
        pumpMultiplier * t_BW_ramp_module_cycle_adj +
        2 * t_BW_wait_module_cycle_adj;
    } else if (UFData.uFBWProtocolID == 2) {
      result =
        commonCalculation +
        getDatByKey("backwash_AirScour") +
        6 * getDatByKey("valveOpenCloseDuration") +
        4 * getDatByKey(TYPICAL_WAIT_DURATION[UFData.pUFTechnologyID]) +
        getDatByKey(TYPICAL_PUMP_RAMP[UFData.pUFTechnologyID]);
    }
    return result;
  };

  const getCEBnCIPData = useMemo(() => {
    const { backwash_design, disinfectionCEB, alkaliOxidantCEB, acidCEB } = UFData;
    const calculateRatio = value => (value > 0 ? Number(value * 60) / t_normal_module_cycle : 1000000);

    const t_BW_module_cycle = getCalculateData();
    const t_normal_module_cycle = Number(backwash_design) + t_BW_module_cycle;
    const ceb3Raw = calculateRatio(disinfectionCEB);
    const ceb2Raw = calculateRatio(alkaliOxidantCEB);
    const ceb1Raw = calculateRatio(acidCEB);
    const cipRaw = UFData.cIP > 0 ? (Number(UFData.cIP) * 60 * 24) / t_normal_module_cycle : 1000000;
    const miniCipRaw = UFData.miniCIP > 0 ? (UFData.miniCIP * 60 * 24) / t_normal_module_cycle : 1000000;

    const N_F_per_clean_min = Math.min(ceb1Raw, ceb2Raw, ceb3Raw, cipRaw, miniCipRaw, 1000000);

    const mCIPRatio = UFData.miniCIP > 0 ? miniCipRaw / N_F_per_clean_min : 0;
    const cipRatio = UFData.cIP > 0 ? cipRaw / N_F_per_clean_min : 0;
    const ceb1Ratio = UFData.acidCEB > 0 ? ceb1Raw / N_F_per_clean_min : 0;
    const CEB2Ratio = UFData.alkaliOxidantCEB > 0 ? ceb2Raw / N_F_per_clean_min : 0;
    const ceb3Ratio = UFData.disinfectionCEB > 0 ? ceb3Raw / N_F_per_clean_min : 0;

    const calculateValue = (ratio, min, flow) => {
      const isDisabled = flow === "mCIP" ? isMCIPDisabled : isCEBDisabled;
      if (isDisabled && flow) {
        return "0";
      }
      return parseInt(ratio * parseInt(min + 0.5) + 0.5).toString();
    };
    return {
      N_BW_per_AS: parseInt(backwash_design / backwash_design + 0.5).toString(),
      N_F_per_CEB1: calculateValue(ceb1Ratio, N_F_per_clean_min, "CEB"),
      N_F_per_CEB2: calculateValue(CEB2Ratio, N_F_per_clean_min, "CEB"),
      N_F_per_CEB3: calculateValue(ceb3Ratio, N_F_per_clean_min, "CEB"),
      N_F_per_mCIP: calculateValue(mCIPRatio, N_F_per_clean_min, "mCIP"),
      N_F_per_CIP: calculateValue(cipRatio, N_F_per_clean_min),
      t_normal_module_cycle: t_normal_module_cycle.toString(),
    };
  }, [UFData, isMCIPDisabled, isCEBDisabled]);

  const getChemicalCIP = useMemo(() => {
    const acid = isValuesZero(UFData.mineralValue_CIP, UFData.organicValue_CIP) ? 0 : 1;
    const alkali = isValuesZero(UFData.alkaliValue_CIP, UFData.oxidantValue_CIP) ? 0 : 1;
    const Surfactant = isValuesZero(UFData.oxidant2Value_CIP) ? 0 : 1;

    return Math.max(1, acid + alkali + Surfactant).toString();
  }, [UFData]);

  const getValidatedValue = (key, value) => (disabledUFFlow && key.includes(disabledUFFlow) ? "0" : value?.toString());

  const getChemicalMiniCIP = useMemo(() => {
    const checkValues = (value1, value2, enabled) => {
      let result = 0;
      if (value1 > 0 && enabled) {
        result = 2;
      } else if (value1 > 0 || value2 > 0) {
        result = 1;
      }
      return result;
    };

    const firstData = checkValues(
      UFData.mineralValue_MiniCIP,
      UFData.organicValue_MiniCIP,
      UFData.mineralEnabled_Ind_MiniCIP,
    );
    const secondData = checkValues(
      UFData.alkaliValue_MiniCIP,
      UFData.oxidantValue_MiniCIP,
      UFData.alkaliEnabled_Ind_MiniCIP,
    );

    return Math.max(1, firstData, secondData).toString();
  }, [UFData]);

  const getfeedWaterParamsPayload = () => {
    if (!ufChemicalAdjustment) return {};

    const { feedData } = ufChemicalAdjustment;

    const feedWaterParams = {
      feed_water: getFeedwaterParams(feedData?.feed_water, StreamStoreData),
      Feed_acid_pH: Number(feedData?.adjusted_water?.ph || StreamStoreData.pH).toString(),
    };

    if (feedData?.adjusted_water) {
      feedWaterParams.adjusted_water = getFeedwaterParams(feedData?.adjusted_water, StreamStoreData);
    }
    return feedWaterParams;
  };

  const getModuleDetails = (moduleID, moduleList = ufModules) => {
    let moduleData = {};
    Logger.log("getModuleDetails - moduleID: ", moduleID, moduleList);
    const selectedModule = moduleList.find(m => m.ufmoduleId == moduleID);
    if (selectedModule) {
      const moduleFlowData = ufModuleFlowVM.find(vfvm => vfvm.ufmoduleId == moduleID) || {};
      const modulePressureData = ufModulePressureRatingVM.find(pvm => pvm.ufmoduleID == moduleID) || {};
      moduleData = {
        ...moduleFlowData,
        ...selectedModule,
        ...modulePressureData,
      };
    }
    return moduleData;
  };

  const validData = (data, label) => (data ? data[label] : 0);

  const getPumpDataParams = () => {
    const result = {};
    if (!pumpCofig?.pupmList?.length) {
      Logger.error("Pump data not found in pumpCofig.pupmList");
      return result;
    }

    const pumpConfigAsObj = pumpCofig.pupmList.reduce((acc, pump) => ({ ...acc, [pump.pumpID]: pump }), {});
    for (const key in UF_PUMP_LIST) {
      const { motorEfficiency, pumpEfficiency } = pumpConfigAsObj[UF_PUMP_LIST[key]] || {};
      result[`Eff_motor_${key}`] = getValidatedValue(key, motorEfficiency);
      const pumpKey = key === "compressor" ? `Eff_${key}` : `Eff_pump_${key}`;
      const value = getValidatedValue(key, pumpEfficiency);
      result[pumpKey] = value;
    }
    return result;
  };

  const getModulePayloadParams = (moduleData, toUpperCase = false) => {
    const result = {};
    if (moduleData) {
      const mapping = toUpperCase ? MODULE_DETAILS_MAPPING_UPPER_CASE : MODULE_DETAILS_MAPPING;
      Object.entries(mapping).forEach(([key, value]) => {
        const propertyValue = moduleData[value]?.toString() ?? "";
        result[key] = toUpperCase ? propertyValue.toUpperCase() : propertyValue;
      });
    } else {
      Logger.error("Module data not found in getModuleParams");
    }
    return result;
  };

  const getDurationParams = companyCode => {
    DURATION_FIELDS_MAPPING["t_wait"] = TYPICAL_WAIT_DURATION[companyCode];
    DURATION_FIELDS_MAPPING["t_ramp"] = TYPICAL_PUMP_RAMP[companyCode];
    const result = Object.entries(DURATION_FIELDS_MAPPING).reduce((acc, [key, value]) => {
      acc[key] = getValidatedValue(key, UFData[value] / 60);
      return acc;
    }, {});
    return result;
  };
  const getStreamDataParams = () =>
    Object.entries(STREAMDATA_FIELDS_MAPPING).reduce((acc, [key, value]) => {
      const paramValue = getValidatedValue(key, StreamStoreData?.[value]);
      return { ...acc, [key]: paramValue };
    }, {});

  const getStanardFieldsParams = () =>
    Object.entries(STANDARD_FIELDS_MAPPING).reduce((acc, [key, value]) => {
      const paramValue = getValidatedValue(key, UFData[value]);
      return { ...acc, [key]: paramValue };
    }, {});

  const getModuleParams = moduleData => {
    const isIntegraPacTRack = moduleData.integraPacInd || moduleData.tRack;
    return {
      IP_Mod_skid: (isIntegraPacTRack ? UFData.modulesPerSkid : UFData.modulesPerTrain).toString(),
      IP_Skids_train: (isIntegraPacTRack ? UFData.skidsPerTrain : "1").toString(),
      ...getModulePayloadParams(moduleData),
      ...getModulePayloadParams(moduleData, true),
      Company: moduleData.companyName,
    };
  };

  const getMCIPAcidBaseParams = () => {
    const getValue = (indicator, value) => {
      if (isMCIPDisabled || indicator) {
        return "0";
      }
      return value.toString();
    };

    return {
      mCIP_acid_conc: getValue(isValueInPh, UFData.mineralValue_MiniCIP),
      mCIP_acid_pH: getValue(!isValueInPh, UFData.mineralValue_MiniCIP),
      mCIP_base_pH: getValue(!isValueInPh, UFData.alkaliValue_MiniCIP),
      mCIP_base_conc: getValue(isValueInPh, UFData.alkaliValue_MiniCIP),
    };
  };

  const getChemicalName = (id, isDisabled, field = "symbol") => {
    if (isDisabled) return "0";

    const chemicalData = validData(chemicalListById[id], field, field === "name");
    return changeChemicalFormat(chemicalData).toString();
  };

  const getMCIPChemicalNamesParams = () => ({
    mCIP_org_acid_name: getChemicalName(UFData.organicChemId_MiniCIP, isMCIPDisabled, "name"),
    mCIP_acid_name: getChemicalName(UFData.mineralChemId_MiniCIP, isMCIPDisabled),
    mCIP_base_name: getChemicalName(UFData.alkaliChemId_MiniCIP, isMCIPDisabled),
    mCIP_ox_name: getChemicalName(UFData.oxidantChemId_MiniCIP, isMCIPDisabled),
  });

  const getCEBChemicalNamesParams = () => ({
    CEB1_org_acid_name: getChemicalName(UFData.organicChemId_CEB, isCEBDisabled, "name"),
    CEB1_acid_name: getChemicalName(UFData.mineralChemId_CEB, isCEBDisabled),
    CEB2_base_name: getChemicalName(UFData.alkaliChemId_CEB, isCEBDisabled),
    CEB2_ox_name: getChemicalName(UFData.oxidantChemId_CEB, isCEBDisabled),
    CEB3_ox_name: getChemicalName(UFData.disOxidantChemId_CEB, isCEBDisabled),
  });

  const getUFCoagulantChemicalParams = () => {
    const activeCoagulantId = ufChemicalAdjustment.data?.coagulant?.chemicalId || 0;
    const activeConagulant = chemicalListById[activeCoagulantId];
    const list = activeConagulant ? [activeConagulant] : [];
    return getChemicalDetail(list, COAGULANT_CHEMICALS);
  };

  const getUFSystemChemicalParams = () => {
    const payloadData = getUFCoagulantChemicalParams();
    for (const chemicalType in UF_CHEMICALS_TYPES_SYMBOL) {
      const chemDetails = getSelectedChemicalByType(chemicalType);
      const data = getChemicalDetail(chemDetails, UF_CHEMICALS_TYPES_SYMBOL[chemicalType]);
      Object.assign(payloadData, data);
    }
    return payloadData;
  };

  const getConfigPayload = moduleDatails => {
    if (!chemicalListById || !chemicalListByName) return {};

    if (!moduleDatails) {
      moduleDatails = getModuleDetails(UFData.uFModuleID);
    }

    const ufReport = {
      WaterTypeID: StreamStoreData?.waterTypeID.toString(),
      WaterSubTypeID: StreamStoreData?.waterSubTypeID.toString(),
      Guideline_number: StreamStoreData?.waterSubTypeID.toString(),
      Flow_Design:
        selectedEndNode == "startNode"
          ? convertUnitString(feedFlowRate, "m³/h", 1)
          : convertUnitString(productFlowRate, "m³/h", 1),
      Flag_Design_Flow: selectedEndNode == "startNode" ? "0" : "2",
      Strainer_Size: UFData.strainerSize.toString(),

      Flux_Filter_target: convertUnitString(UFData.filtrateFlux, "LMH", 4),
      Flux_BW: convertUnitString(UFData.backwashFlux, "LMH", 4),
      Flux_CEB: isCEBDisabled ? "0" : convertUnitString(UFData.cEBFlux, "LMH", 4),
      Flow_FF: convertUnitString(UFData.forwardFlushFlow, "m³/h", 1),
      Flow_AS: convertUnitString(UFData.airFlow, "Nm³/h", 18),
      Flow_AS2: convertUnitString(UFData.aerationAirFlow, "Nm³/h", 18),
      Flow_CIP_recycle: convertUnitString(UFData.recycleFlowRate, "m³/h", 1),
      Flow_mCIP_recycle: isMCIPDisabled ? "0" : convertUnitString(UFData.recycleFlowRate_MiniCIP, "m³/h", 1),

      t_MIT_module_day: UFData.offlinetimepertrain.toString(),
      TMP_slope_BW: (UFData.backwash_Filtration / 1000).toString(),
      TMP_slope_CEB1: isCEBDisabled ? "0" : (UFData.acidCEB_Filtration / 1000).toString(),
      TMP_slope_CEB2: isCEBDisabled ? "0" : (UFData.alkaliCEB_Filtration / 1000).toString(),
      TMP_slope_CIP: (UFData.cIP_Filtration / 1000).toString(),
      TMP_slope_mCIP: isMCIPDisabled ? "0" : (UFData.miniCIP_Filtration / 1000).toString(),
      Standby_Option:
        UFData.uFBWCEBStandbyOptionID == 1
          ? "Constant operating flux, variable system output"
          : "Constant system output, variable operating flux",
      Flag_CIP_standby: UFData.uFBWCEBStandbyOptionID == 1 ? "0" : "2",
      Flag_Storage_Tank: UFData.uFBWCEBStandbyOptionID == 1 ? "1" : "0",

      Flag_BW: (UFData.uFBWWaterTypeID - 1).toString(),
      Flag_FF: (UFData.uFBWFlushWaterTypeID - 1).toString(),
      Flag_BW_Protocol: UFData.uFBWProtocolID == 1 ? "2" : moduleDatails?.moduleType === "Inge" ? "3" : "0",

      BW_ox_name: validData(chemicalListById[UFData.oxidantChemId_BW], "symbol").toString(),
      CEB1_acid_conc: isCEBDisabled ? "0" : isValueInPh ? "0" : UFData.mineralValue_CEB.toString(),
      CEB1_acid_pH: isCEBDisabled ? "0" : isValueInPh ? UFData.mineralValue_CEB.toString() : "0",
      CEB2_base_conc: isCEBDisabled ? "0" : isValueInPh ? "0" : UFData.alkaliValue_CEB.toString(),
      CEB2_base_pH: isCEBDisabled ? "0" : isValueInPh ? UFData.alkaliValue_CEB.toString() : "0",
      CIP_acid_pH: isValueInPh && UFData.mineralEnabled_Ind_CIP ? UFData.mineralValue_CIP.toString() : "0",
      CIP_base_conc: isValueInPh ? "0" : UFData.alkaliValue_CIP.toString(),
      CIP_base_pH: isValueInPh ? UFData.alkaliValue_CIP.toString() : "0",
      CIP_acid_conc: isValueInPh ? "0" : UFData.mineralValue_CIP.toString(),

      CIP_acid_name: changeChemicalFormat(validData(chemicalListById[UFData.mineralChemId_CIP], "symbol")).toString(),
      CIP_base_name: changeChemicalFormat(validData(chemicalListById[UFData.alkaliChemId_CIP], "symbol")).toString(),
      CIP_ox_name: changeChemicalFormat(validData(chemicalListById[UFData.oxidantChemId_CIP], "symbol")).toString(),
      CIP_SLS_name: changeChemicalFormat(validData(chemicalListById[UFData.oxidant2ChemId_CIP], "symbol")).toString(),
      CIP_org_acid_name: changeChemicalFormat(
        validData(chemicalListById[UFData.organicChemId_CIP], "name", true),
      ).toString(),

      P_air_max: convertUnitString(UFData.maxAirScourPressure, "bar", 3),
      P_ADBW_max: convertUnitString(UFData.maxAirProcPressure, "bar", 3),
      P_Filtrate: convertUnitString(UFData.filteratePressure, "bar", 3),
      Delta_P_piping_CIP: convertUnitString(UFData.cIPPipingPreDrop, "bar", 3),
      Delta_P_piping_mCIP: isMCIPDisabled ? "0" : convertUnitString(UFData.cIPPipingPreDrop, "bar", 3),
      Delta_P_piping_BW: convertUnitString(UFData.backwashPipingPreDrop, "bar", 3),
      Delta_P_piping_filtration: convertUnitString(UFData.nonIntegraPacTrainPresDrop, "bar", 3),
      Delta_P_strainer_filtration: convertUnitString(UFData.integraPacFiltrationPreDrop, "bar", 3),
      Power_PLC: convertUnitString(UFData.pLCPowerReqPertrain, "kW", 9),
      Power_valve: convertUnitString(UFData.volvePowerReqPerTrain, "kW", 9),

      Price_Water: operationCost?.rawWater?.toString(),
      Price_Elec: operationCost?.electricity?.toString(),
      Price_Waste: operationCost?.wasteWaterDisposal?.toString(),

      f_CIP_tank: (UFData.cIPTank / 100).toString(),
      f_mCIP_tank: isMCIPDisabled ? "0" : (UFData.cIPTank / 100).toString(),
      f_ADBW: (UFData?.aDBWDisplacement / 100).toString(),
      f_BW_tank_safety_margin: (UFData.bWTank / 100).toString(),
      Recovery_Pretreat: (UFData.strainerRecovery / 100).toString(),
      tocRejection: Number(UFData.tocRejection),
      f_Filtrate_tank_safety_margin: (UFData.filterateTank / 100).toString(),
      f_BW_tank_feed: (UFData.bWTankRefillRate / 100).toString(),
      N_Chem_CIP: getChemicalCIP,
      N_Chem_mCIP: isMCIPDisabled ? "0" : getChemicalMiniCIP,

      ...getCEBnCIPData,
      ...UF_CONFIG_COMMON_PAYLOAD,
      ...getAdjustedFeedChemicalsData(),
      ...getDurationParams(UFData.pUFTechnologyID),
      ...getPumpDataParams(),
      ...getStreamDataParams(),
      ...getMCIPAcidBaseParams(),
      ...getStanardFieldsParams(),
      ...getfeedWaterParamsPayload(),
      ...getUFSystemChemicalParams(),
      ...getCEBChemicalNamesParams(),
      ...getMCIPChemicalNamesParams(),
      ...getModuleParams(moduleDatails),
      ...getUFConfigChemicalParams(chemicalListById, UFData),
    };
    const payload = {
      projectID,
      caseID: caseID,
      requestConfig: {
        ...ufReport,
      },
    };
    return payload;
  };

  //TODO: This code is DRY for now
  const getFeedChemicalsAfterChemAdjustment = (prefix, source) => {
    const result = {};
    if (source && chemicalListById) {
      const chemical = chemicalListById[source.chemicalId];
      if (chemical) {
        result[`${prefix}_bulk_conc`] = Number(chemical.bulkConcentration / 100).toString();
        result[`${prefix}_conc`] = prefix === "Feed_acid" ? "0" : Number(source.value).toString(); // Feed_acid_conc always zero , value will be calculated by calc engine
        result[`${prefix}_name`] = changeChemicalFormat(chemical.symbol);
        result[`${prefix}_price`] = Number(chemical.bulkPrice).toString();
        result[`${prefix}_density`] = Number(chemical.bulkDensity).toString();
      }
    }
    return result;
  };

  const getAdjustedFeedChemicalsData = () => {
    const { oxidant, coagulant, phDown } = ufChemicalAdjustment?.data || {};
    const oxCoagAdjustmentData = {
      ...getFeedChemicalsAfterChemAdjustment("Feed_acid", phDown),
      ...getFeedChemicalsAfterChemAdjustment("Feed_ox", oxidant),
      ...getFeedChemicalsAfterChemAdjustment("Feed_coag", coagulant),
    };

    return oxCoagAdjustmentData;
  };

  const updateActiveModule = (moduleID, ufModules, ufData = UFData) => {
    const activeUFModule = getModuleDetails(moduleID, ufModules);
    const { defaultInputRangeConfig, updatedDataFields } = getModuleBasedInputRangeConfigs(activeUFModule);
    const calculatedUFFields = calculateUFFields(ufData, activeUFModule);
    const offlinetimepertrain = activeUFModule.drinkingWaterInd
      ? WATER_TYPES_OFFLINE_TRAINS.DRINKNG
      : WATER_TYPES_OFFLINE_TRAINS.NON_DRINKING;
    const data = {
      ...updatedDataFields,
      ...calculatedUFFields,
      redundantTrains: "0",
      offlinetimepertrain: offlinetimepertrain,
      uFModuleID: moduleID,
      uFBWProtocolID: activeUFModule?.newModuleLongName?.indexOf("UXA") >= 0 ? "1" : "2",
    };
    dispatch(
      updateUFStoreData({
        data,
        activeUFModule,
        ingeTROption: null,
        isUfDataUpdated: true,
        defaultInputRangeConfig,
        isCustomConfigAvail: true,
        isForDrinkingWater: activeUFModule.drinkingWaterInd,
        sliderMin: activeUFModule.integraPacInd ? UF_MODULE_PER_RACK_RANGE.min : 0,
        sliderMax: activeUFModule.integraPacInd ? UF_MODULE_PER_RACK_RANGE.max : 0,
      }),
    );
    Logger.log("updateActiveModule - calculateUFFields: ", calculatedUFFields);
    return activeUFModule;
  };

  const calculateUFConfigTable = (calcEngineData, withStandBy) => {
    let recommended_configs = [];
    if (!calcEngineData || !activeUFModule) return recommended_configs;
    const offlineTimePerTrain = withStandBy ? UFData.offlinetimepertrain : null;
    if (activeUFModule.tRack) {
      recommended_configs = calculateIngeUFConfigWithRack(
        activeUFModule.ufmoduleId,
        UFData.skidsPerTrain,
        withStandBy,
        offlineTimePerTrain,
        calcEngineData,
      );
    } else if (calcEngineData.integraPac) {
      recommended_configs = calculateDuPontUFConfigWithRack(calcEngineData, offlineTimePerTrain, withStandBy);
    } else {
      const isInge = isIngeSelected(UFData.pUFTechnologyID);
      recommended_configs = calculateUFConfigWithNoRack(calcEngineData, offlineTimePerTrain, withStandBy, isInge);
    }
    return recommended_configs;
  };

  const updateDataOnTROptionChange = (selectedTROption, isCustomConfigAvail = true) => {
    if (selectedTROption && activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const skidsPerTrain = selectedTROption.replace("TR", "");
      const { min: sliderMin, max: sliderMax } = INGE_MODULE_TR_RANGES[ufmoduleId]?.[selectedTROption] || {};
      const sliderData = { sliderMin, sliderMax };
      const updatedData = {
        data: { skidsPerTrain },
        ...sliderData,
        calcEngineDataRefreshCount: isCustomConfigAvail ? 1 : 0,
        isRecommendationCalucalting: false,
        isCustomConfigAvail: isCustomConfigAvail,
      };
      dispatch(updateUFStoreData(updatedData));
    }
  };

  const getSelectedTROption = calcEngineData => {
    if (activeUFModule?.tRack) {
      const { ufmoduleID } = activeUFModule;
      const moduleTROptionRanges = INGE_MODULE_TR_OPTIONS_RANGE[ufmoduleID];
      const { n_modules_target } = calcEngineData;
      const trOptionsKeys = Object.keys(moduleTROptionRanges);
      const defaultTR = trOptionsKeys.length === 1 ? "TR2" : "TR4";
      const trOption = trOptionsKeys.find(key => n_modules_target <= moduleTROptionRanges[key]) || defaultTR;
      Logger.log("getSelectedTROption TR Option: ", ufmoduleID, trOption, calcEngineData);
      return trOption;
    }
  };

  const getRecommentdationData = calcEngineResponse => {
    const withStandBy = +calcEngineResponse?.standBy === 2;
    return calculateUFConfigTable(calcEngineResponse, withStandBy);
  };

  const generateRecommendedConfigs = calcEngineResponse => {
    if (!calcEngineResponse) return;

    dispatch(updateUFStoreData({ isRecommendationCalucalting: true, recommended_configs: [] }));

    const recommended_configs = getRecommentdationData(calcEngineResponse);
    const customConfigData = {
      isUFConfigLoading: false,
      isCustomConfigAvail: false,
      calcEngineDataRefreshCount: 1,
    };

    Logger.log("[TR Option] generateRecommendedConfigs:", ingeTROption, ingeTROption, isCustomConfigAvail);
    if (isCustomConfigAvail) {
      if (!activeUFModule?.tRack || ingeTROption) {
        customConfigData.isUfDataUpdated = true;
        const defaultConfig = getDefaultUFConfiguration(recommended_configs[0]);
        customConfigData.recommended_configs = recommended_configs;
        Object.assign(customConfigData, defaultConfig);
      } else {
        customConfigData.ingeTROption = getSelectedTROption(calcEngineResponse);
        customConfigData.isUFConfigLoading = true;
        if (customConfigData.ingeTROption) {
          setTimeout(() => updateDataOnTROptionChange(customConfigData.ingeTROption), 200); // Timeout to ensure data has been updated in redux
        }
      }
    } else {
      customConfigData.recommended_configs = recommended_configs;
    }

    Logger.log("TR Option defaultConfig: ", isCustomConfigAvail, activeUFModule?.tRack, ingeTROption, customConfigData);
    dispatch(updateUFStoreData(customConfigData));
  };

  const isWaterSubtypeAvailable = useMemo(
    () => StreamStoreData?.waterTypeID > 0 && StreamStoreData?.waterSubTypeID > 0,
    [StreamStoreData],
  );

  const fetchUFConfig = async (moduleDatails, skipRecommending) => {
    if (!isWaterSubtypeAvailable) {
      Logger.log(
        "Cancelling fetchUFConfig - No Water Type/SubType: ",
        StreamStoreData?.waterTypeID,
        StreamStoreData?.waterSubTypeID,
      );
      return;
    }

    Logger.log("fetchUFConfig - isRecommendationCalucalting: ", isRecommendationCalucalting);
    if (!skipRecommending) {
      dispatch(updateUFStoreData({ autoSelectTRack: false }));
    }
    if (!isRecommendationCalucalting || skipRecommending) {
      dispatch(updateUFStoreData({ isUFConfigLoading: true }));
    }
    moduleDatails = moduleDatails || activeUFModule;
    const payload = getConfigPayload(moduleDatails);
    const { ufReport } = formatReportPayload(payload.requestConfig) || {};
    payload.requestConfig = ufReport;
    let ufConfigData = {};
    try {
      const { data: calcEngineResponse } = await postRequest(API_URLS.ufConfig, payload);
      if (calcEngineResponse?.statusCode === "OK" && calcEngineResponse?.responseConfigVM) {
        ufConfigData = { ...calcEngineResponse.responseConfigVM };
        if (unitConfig.selectedUnits[4] !== "LMH") {
          ufConfigData.flux_Filter_actual = unitConversionByName(ufConfigData.flux_Filter_actual, unitTypeFlux, "LMH");
        }
        if (skipRecommending || isRecommendationCalucalting) {
          dispatch(handleCalcEngineResponse(ufConfigData));
          dispatch(updateUFStoreData({ isUFConfigLoading: false }));
        } else {
          generateRecommendedConfigs({
            ...ufConfigData,
            standBy: UFData.uFBWCEBStandbyOptionID,
            integraPac: moduleDatails?.integraPacInd,
            activeModuleName: moduleDatails?.newModuleLongName,
          });
        }
      }
    } catch (err) {
      dispatch(updateUFStoreData({ isUFConfigLoading: false }));

      Logger.error("UF config API Error: ", err);
    }
    return ufConfigData;
  };

  /**
   * Updates the non-mini CIP fields value based on the provided value.
   * If the provided value matches the mini CIP only feature ID, it sets the fields to "0".
   * Otherwise, it sets the fields to their default values.
   *
   * @param {number} value - The value to compare against the mini CIP only feature ID.
   */
  const updateNonMiniCIPFieldsValue = (value = UFData.ufSpecialFeatureID) => {
    const { isMiniCipOnly } = UF_SPECIAL_FEATURE;
    let data = {};
    const fields = ["cEBFlux", "acidCEB", "alkaliOxidantCEB"];
    if (+value === +isMiniCipOnly) {
      data = fields.reduce((acc, key) => ({ ...acc, [key]: "0" }), {});
    } else if (+UFData.ufSpecialFeatureID === +isMiniCipOnly) {
      data = getUFFieldsDefaultValues(activeUFModule, fields);
    }
    dispatch(updateUFStoreData({ data }));
  };

  return {
    fetchUFConfig,
    getModuleDetails,
    getConfigPayload,
    getCalculateData,
    updateActiveModule,
    getRecommentdationData,
    getUFSystemChemicalParams,
    updateNonMiniCIPFieldsValue,
    ufMaxPumpPressureValues,
  };
};

export default useUFConfig;
