import SVGContainer from "../SVGContainer";

const TransferOwnership = () => (
  <SVGContainer width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2610_61711)'>
      <path
        d='M15.5132 6.04353C15.5132 2.98549 13.0356 0.507812 9.97752 0.507812C6.91949 0.507812 4.44181 2.98549 4.44181 6.04353C4.44181 7.89174 5.34806 9.53013 6.74092 10.5346C6.73199 10.5391 6.72529 10.5413 6.72083 10.5435C5.72306 10.9654 4.82797 11.5703 4.05788 12.3426C3.29224 13.1069 2.68269 14.0129 2.26324 15.01C1.85055 15.9865 1.62782 17.0327 1.60699 18.0926C1.60639 18.1165 1.61057 18.1402 1.61928 18.1623C1.62799 18.1845 1.64105 18.2047 1.65769 18.2218C1.67433 18.2389 1.69422 18.2524 1.71618 18.2617C1.73814 18.2709 1.76173 18.2757 1.78556 18.2757H3.12261C3.2186 18.2757 3.29895 18.1975 3.30119 18.1016C3.34583 16.3783 4.03556 14.7645 5.25654 13.5413C6.5177 12.2757 8.19181 11.5792 9.97752 11.5792C13.0356 11.5792 15.5132 9.10156 15.5132 6.04353ZM9.97752 9.88281C7.85699 9.88281 6.13824 8.16406 6.13824 6.04353C6.13824 3.92299 7.85699 2.20424 9.97752 2.20424C12.0981 2.20424 13.8168 3.92299 13.8168 6.04353C13.8168 8.16406 12.0981 9.88281 9.97752 9.88281ZM12.3213 14.8158H18.2141C18.3123 14.8158 18.3927 14.7355 18.3927 14.6373V13.3873C18.3927 13.2891 18.3123 13.2087 18.2141 13.2087H14.2744L15.328 11.8672C15.3523 11.8359 15.3656 11.7974 15.3659 11.7578C15.3659 11.6596 15.2856 11.5792 15.1873 11.5792H13.5668C13.4574 11.5792 13.3548 11.6306 13.2856 11.7154L11.7565 13.6596C11.6583 13.7846 11.6048 13.9408 11.6048 14.1016C11.607 14.4967 11.9262 14.8158 12.3213 14.8158ZM17.6784 16.2444H11.7856C11.6873 16.2444 11.607 16.3248 11.607 16.423V17.673C11.607 17.7712 11.6873 17.8516 11.7856 17.8516H15.7253L14.6717 19.1931C14.6474 19.2244 14.6341 19.2628 14.6338 19.3025C14.6338 19.4007 14.7141 19.481 14.8123 19.481H16.4329C16.5423 19.481 16.6449 19.4297 16.7141 19.3449L18.2431 17.4007C18.3414 17.2757 18.3949 17.1194 18.3949 16.9587C18.3927 16.5636 18.0735 16.2444 17.6784 16.2444Z'
        fill='black'
        fillOpacity='0.7'
      />
    </g>
    <defs>
      <clipPath id='clip0_2610_61711'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </SVGContainer>
);

export default TransferOwnership;
