import { useEffect } from "react";
import { Modal } from "react-bootstrap";

import { TOAST_DEFAULT_DURATION, TOAST_DEFAULT_DURATION_ERROR } from "@constants/global.constants";

import { combineClassNames } from "@utils/appUtils";

import IconButton from "@components/IconButton";
import { ToastErrorIcon, ToastSuccessIcon, ToastWarningIcon } from "@components/SVGs";

import CloseIcon from "@common/icons/CloseIcon";
import StyledModal from "@common/styles/components/modals/CustomModal";

import { TOAST_NOTIFICATION_TYPES, useToastNotification } from "./WPToastNotificationProvider";

import "./WPToastNotification.scss";

const TOAST_ICONS = {
  [TOAST_NOTIFICATION_TYPES.SUCCESS]: <ToastSuccessIcon />,
  [TOAST_NOTIFICATION_TYPES.ERROR]: <ToastErrorIcon />,
  [TOAST_NOTIFICATION_TYPES.WARNING]: <ToastWarningIcon />,
};

const DEFAULT_DURATIONS = {
  [TOAST_NOTIFICATION_TYPES.SUCCESS]: TOAST_DEFAULT_DURATION,
  [TOAST_NOTIFICATION_TYPES.ERROR]: TOAST_DEFAULT_DURATION_ERROR,
};

const WPToastNotification = () => {
  const { toastData, removeToastNotification } = useToastNotification();

  useEffect(() => {
    if (toastData) {
      const duration = toastData.duration || DEFAULT_DURATIONS[toastData.type];
      const timer = setTimeout(() => removeToastNotification(), duration);
      return () => clearTimeout(timer);
    }
  }, [toastData, removeToastNotification]);

  if (!toastData) return null;

  return (
    <StyledModal
      show
      backdrop={false}
      data-test-id='toast-notification'
      onHide={removeToastNotification}
      maxWidth='800px'
      className={combineClassNames("toast-container toast-z-index", `toast-${toastData.type}`)}
    >
      <Modal.Body>
        <div className='message-container'>
          <span className='icon'>{TOAST_ICONS[toastData.type]}</span>
          <span className='message'>{toastData.message}</span>
          <IconButton onClick={removeToastNotification} tooltip='Close'>
            <CloseIcon />
          </IconButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
};

export default WPToastNotification;
