import styled from "styled-components";

const StyledSVG = styled.svg`
  fill: var(--du-color-icon);
  ${({ disabled }) =>
    disabled &&
    `
    fill: var(--du-color-icon-disabled)
  `}
  path {
    fill-opacity: ${({ hoverEffect }) => (hoverEffect ? 0.8 : 1)};
    transition: fill-opacity 0.3s ease;
  }
`;

const Container = styled.div`
  &:hover ${StyledSVG} path {
    ${({ disabled, hoverColor }) =>
      !disabled &&
      `
      fill-opacity:1;
      fill: ${hoverColor || "var(--du-color-hover)"};
      stroke: ${hoverColor ? "" : "var(--du-color-hover)"};
      stroke-width: 1;
    `}
  }
`;

const SVGContainer = props => (
  <Container disabled={props.disabled} hoverColor={props.hoverColor} hoverEffect={props.hoverEffect}>
    <StyledSVG {...props} />
  </Container>
);

export default SVGContainer;
