
import SVGContainerPath from "../SVGContainerPath";

const LargeMoreIcon = () => (
  <SVGContainerPath width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 4.25C14 4.84674 14.2371 5.41903 14.659 5.84099C15.081 6.26295 15.6533 6.5 16.25 6.5C16.8467 6.5 17.419 6.26295 17.841 5.84099C18.2629 5.41903 18.5 4.84674 18.5 4.25C18.5 3.65326 18.2629 3.08097 17.841 2.65901C17.419 2.23705 16.8467 2 16.25 2C15.6533 2 15.081 2.23705 14.659 2.65901C14.2371 3.08097 14 3.65326 14 4.25ZM14 15.5C14 16.0967 14.2371 16.669 14.659 17.091C15.081 17.5129 15.6533 17.75 16.25 17.75C16.8467 17.75 17.419 17.5129 17.841 17.091C18.2629 16.669 18.5 16.0967 18.5 15.5C18.5 14.9033 18.2629 14.331 17.841 13.909C17.419 13.4871 16.8467 13.25 16.25 13.25C15.6533 13.25 15.081 13.4871 14.659 13.909C14.2371 14.331 14 14.9033 14 15.5ZM14 26.75C14 27.3467 14.2371 27.919 14.659 28.341C15.081 28.7629 15.6533 29 16.25 29C16.8467 29 17.419 28.7629 17.841 28.341C18.2629 27.919 18.5 27.3467 18.5 26.75C18.5 26.1533 18.2629 25.581 17.841 25.159C17.419 24.7371 16.8467 24.5 16.25 24.5C15.6533 24.5 15.081 24.7371 14.659 25.159C14.2371 25.581 14 26.1533 14 26.75Z" fill="black" fillOpacity="0.85" />
  </SVGContainerPath>
);

export default LargeMoreIcon;
