import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  marketSegments: [],
  defaultTechnologies: [],
  subTechnologies: [],
  waterLibraryList: {},
  unreadNotifications: [],
  chemicalLibraryList: [],
  chemicalCategoryData: {},
  fullScreenLoaderMessage: null,
};

export const GlobalDataSlice = createSlice({
  name: "GlobalData",
  initialState,
  reducers: {
    updateGlobalData: (state, action) => {
      const { type, value } = action.payload;
      state[type] = value;
    },
  },
});

export const { updateGlobalData } = GlobalDataSlice.actions;

export default GlobalDataSlice.reducer;
