import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import useUFConfig from "@hooks/useUFConfig";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { formatPressureValue } from "../UFDiagramsUtils/UFDiagramHelper";

const GAS_METRIC_UNIT = "Nm³/h";

const UFDiagramAirPumpDetails = () => {
  const { unitConversionByName, convertFromMetric } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [airScour, setAirScour] = useState(0);
  const { ufMaxPumpPressureValues } = useUFConfig();

  const pressureUnit = unitConfig.selectedUnits[3];

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[18];
    let value = calcEngineData.flow_air_peak || 0;

    if (value && selectedUnit !== GAS_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, GAS_METRIC_UNIT);
    }
    value = convertUptoDigits(value, 0);
    setAirScour(`${value} ${selectedUnit}`);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const maxAirScourPressure = useMemo(() => {
    const maxPressure = convertFromMetric(ufMaxPumpPressureValues.airScour, UNIT_TYPES.PRESSURE);
    return formatPressureValue(maxPressure, pressureUnit);
  }, [pressureUnit, ufMaxPumpPressureValues.airScour]);

  return (
    <>
      <BoldTextElement x='2900' y='2900'>
        Air Scour
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x='2900' y='3025'>
          {airScour}
        </tspan>
        <tspan x='2900' y='3150'>
          {maxAirScourPressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramAirPumpDetails;
