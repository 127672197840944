import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { FooterStrings } from "@utils/StringConstants";

import { WPTypogrpahy } from "@components/WPTypography";

import WaveProImage from "@common/assets/images/Wave-PRO-UF-Logo-02.svg";

import { FOOTER_LINKS, SOCIAL_MEDIA_LINKS } from "./Footer.constants";

import "./Footer.scss";

const FooterLink = ({ href, text, noreferrer }) => (
  <>
    <a href={href} className='footer-link' target='__blank' rel={`noopener${noreferrer ? " noreferrer" : ""}`}>
      {text}
    </a>
  </>
);

const Footer = () => {
  const appVersion = useSelector(state => state.userInfo.data?.applicationVersion);
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <div id='footer-container' className='footer-wrapper'>
        <div className='app-max-width d-flex mx-auto'>
          <div className='footer-left-content'>
            <div className='footer-links-wrapper'>
              {FOOTER_LINKS.map((link, index) => (
                <React.Fragment key={index}>
                  <FooterLink href={link.href} text={link.text} />
                  <div className='vertical-line'></div>
                </React.Fragment>
              ))}
              <a id='teconsent'></a>
            </div>
            <WPTypogrpahy
              className='copy-right-text'
              text={FooterStrings.footerCopyright.replace("${currentYear}", currentYear)}
            />
            <div className='social-icons'>
              {SOCIAL_MEDIA_LINKS.map((social, index) => (
                <FooterLink key={index} href={social.href} text={<social.Icon />} noreferrer />
              ))}
            </div>
          </div>
          <div className='footer-right-content'>
            <div className='text-end'>
              <WPTypogrpahy className='wave-engine-text' asElement='h6' text={FooterStrings.waterAppEngine} />
              <WPTypogrpahy className='dupont-solutions-text' text={FooterStrings.dupontWaterSolutions} />
              {appVersion && <WPTypogrpahy className='version-text' text={FooterStrings.version + appVersion} />}
            </div>
            <div>
              <img src={WaveProImage} alt='WaveProLogo' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
