import { createSlice } from "@reduxjs/toolkit";

import { HomeSideNavIds } from "@features/home/HomeSideNav";

const initialState = {
  //TODO: Rename to activeSideMenu
  activeMenu: HomeSideNavIds.home,
  breadcrumbData: null,
  isSideNavOpen: false, // toggle state of side nab for smaller screens
  isSideNavCollapsible: false,
};
export const SideMenuSlice = createSlice({
  //TODO: Rename to sideMenu or homeSideMenu?
  name: "leftpanel",
  initialState,
  reducers: {
    updateActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    //TODO: Rename to setBreadcrumbData
    setSideMenu: (state, action) => ({ ...state, ...action.payload }),
    setSideNavOpen: (state, action) => {
      state.isSideNavOpen = action.payload;
    },
    setSideNavCollapsible: (state, action) => {
      state.isSideNavCollapsible = action.payload;
    },
  },
});

export const { updateActiveMenu, setSideMenu, setSideNavOpen, setSideNavCollapsible } = SideMenuSlice.actions;

export default SideMenuSlice.reducer;
