import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import DupontLogger from "@utils/DupontLogger";
import { DesignStrings } from "@utils/StringConstants";

import { UF_SPECIAL_FEATURE, WATER_TYPE_IDS } from "@features/feedwater/uf/constants/UFConstants";
import { UF_FIELDS_MAPPING } from "@features/feedwater/uf/constants/UFFieldsMaping";
import { updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

import useUnitConversion from "./useUnitConversion";

const fluxFields = ["backwashFlux", "cEBFlux"];
const pressureFields = [
  "maxAirScourPressure",
  "maxAirProcPressure",
  "filteratePressure",
  "nonIntegraPacTrainPresDrop",
  "integraPacFiltrationPreDrop",
  "backwashPipingPreDrop",
  "acidCEB_Filtration",
  "alkaliCEB_Filtration",
  "cIPPipingPreDrop",
];

export const useUFInputRanges = () => {
  const Logger = DupontLogger("useUFInputRanges");
  const { convertUnit, convertFromMetric, convertToMetric } = useUnitConversion();
  const dispatch = useDispatch();

  const {
    ufInputRangeConfig,
    ufFluxGuideline,
    defaultInputRangeConfig,
    activeUFModule,
    data: UFData,
    ufInputRangeConfigByWaterType,
  } = useSelector(state => state.UFStore);

  const StreamStoreData = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0],
  );
  const { waterTypeID, waterSubTypeID, tempDesign } = StreamStoreData || {};

  const ufInputRangeConfigByWaterTypeById = useMemo(
    () =>
      ufInputRangeConfigByWaterType.reduce((acc, config) => {
        if (!acc[config.waterSubType]) acc[config.waterSubType] = [];
        acc[config.waterSubType].push(config);
        return acc;
      }, {}),
    [ufInputRangeConfigByWaterType],
  );

  /**
   * Bug #137246
   *
   * Calculates the Temperature Correction Factor (TCF) for Filtrate Flux.
   * If the temperature is below 15°C, a derating factor is applied based on a quadratic formula.
   * Otherwise, the factor is 1.
   *
   * If Temp_design < 15 °C Then
   * Derating_Factor = 1 + 0.026512 * (Temp_Design - 15) + 0.00014438 * (Temp_Design - 15)^2
   * Else
   * Derating_Factor = 1
   *
   * Design temp is converted into degree celsius before calculation.
   *
   * @param {number} temperature - The design temperature on Feed Setup.
   * @returns {number} - The Derated Factor.
   */
  const getTempDeratedFactor = temperature => {
    const tempLimit = 15;
    const temp = convertToMetric(temperature, UNIT_TYPES.TEMPERATURE);
    return temp < tempLimit ? 1 + 0.026512 * (temp - tempLimit) + 0.00014438 * Math.pow(temp - tempLimit, 2) : 1;
  };

  const getConfigForFiltrateFlux = (moduleSelected, newTempDesign) => {
    let config = {
      label: "Filtrate Flux",
      defaultValue: convertUnit(1, 4, "LMH"),
      minValue: 1,
      maxValue: 100,
      uom: "L/hr/m²",
      waterSubType: "",
    };

    const filtrateFluxRange = ufFluxGuideline.find(
      ({ waterSubTypeId, moduleType }) => waterSubTypeId === waterSubTypeID && moduleType === moduleSelected.moduleType,
    );

    if (filtrateFluxRange) {
      const { maxValue = 0, minValue = 0, typicalValue } = filtrateFluxRange;
      const temperature = newTempDesign || convertFromMetric(tempDesign, UNIT_TYPES.TEMPERATURE);
      const TCF = getTempDeratedFactor(temperature);
      const defaultCorrected = TCF * parseInt(typicalValue);
      const filterateFlux = Math.max(Math.min(defaultCorrected, maxValue), minValue);

      config = {
        ...config,
        minValue,
        maxValue,
        defaultValue: convertUnit(filterateFlux, 4, "LMH"),
        waterSubType: filtrateFluxRange.waterSubTypeId,
        moduleType: filtrateFluxRange.moduleType,
      };
    }
    return [config];
  };

  /**
   * For PES (Inge): if the Water type is NOT Backwash water, then the (default) forward flush flow
   * should not be calculated, but should be set 0 (but could be changed by user).
   * If the Water type is Backwash water, then the forward flush flow should be calcualted.
   *
   * #137740 formula: forward flush flow = filtrate flux * module area /1000
   *
   */
  const getConfigForForwardFlushFlow = (moduleSelected, filtrateFlux) => {
    const { moduleArea, fiberMaterial } = moduleSelected;
    if (!moduleArea) return;

    const isInge = fiberMaterial.includes("PES");
    const [filtrateFluxRangeConfig] =
      getConfigForFiltrateFlux(moduleSelected, convertFromMetric(tempDesign, UNIT_TYPES.TEMPERATURE)) || [];
    const genericRange = ufInputRangeConfig.find(config => config.label == "Forward Flush Flow");
    const minMaxRanges = {};

    // Default value calculation
    let FFFlow_default = 0;
    if (!isInge || waterTypeID?.toString() === WATER_TYPE_IDS.BACKWASH_WATER.toString()) {
      filtrateFlux = filtrateFlux || convertToMetric(filtrateFluxRangeConfig.defaultValue, UNIT_TYPES.FLUX);
      FFFlow_default = (moduleArea * filtrateFlux) / 1000;
    }

    // Min and Max value calculation
    if (!isInge) {
      const ffMin = moduleArea * filtrateFluxRangeConfig.minValue * 0.0005;
      const ffMax = moduleArea * filtrateFluxRangeConfig.maxValue * 0.001;
      minMaxRanges.minValue = Math.floor(((ffMin * 100) / 25) * 0.25);
      minMaxRanges.maxValue = Math.ceil(((ffMax * 100) / 25) * 0.25);
    }

    return [
      {
        ...genericRange,
        ...minMaxRanges,
        defaultValue: convertUnit(FFFlow_default, 1, "m³/h"),
      },
    ];
  };

  const getConfigForAirFlow = moduleSelected => {
    const genericRange_airflow = ufInputRangeConfig.find(config => config.label == "Air Flow");
    const airFlow_RangeModuleSelected = [
      {
        ...genericRange_airflow,
        minValue: moduleSelected.aS_Flow_min,
        maxValue: moduleSelected.aS_Flow_max,
        defaultValue: convertUnit(moduleSelected.aS_Flow_std, 18, "Nm³/h"),
      },
    ];
    return airFlow_RangeModuleSelected;
  };

  const getConfigForBackwashRinse = () => {
    const backwashRinse = ufInputRangeConfigByWaterType.find(
      item => item.label == "t_CEB_Rinse1&2" && item.waterSubType == waterSubTypeID,
    );
    const backwashRinse_WaterType = [
      {
        ...backwashRinse,
        minValue: backwashRinse?.minValue,
        maxValue: backwashRinse?.maxValue,
        defaultValue: backwashRinse?.defaultValue,
      },
    ];
    return backwashRinse_WaterType;
  };

  const convertValueForSelectUnit = (key, value) => {
    if (fluxFields.includes(key)) {
      return convertUnit(value, 4, "LMH");
    } else if (pressureFields.includes(key)) {
      return convertUnit(value, 3, "bar");
    }
    return value;
  };

  const getModuleBasedInputRangeConfigs = moduleSelected => {
    const defaultInputRangeConfig = getUFFieldsRangeConfig(moduleSelected);
    const defaultValues = Object.keys(defaultInputRangeConfig).reduce((acc, key) => {
      let value = defaultInputRangeConfig[key].defaultValue;
      if (key === "strainerRecovery") {
        value = moduleSelected[key] * 100;
      } else if (key === "strainerSize") {
        value = moduleSelected[key];
      }
      if (value >= 0) {
        const convertedVal = convertValueForSelectUnit(key, value);
        acc[key] = convertedVal;
      }
      return acc;
    }, {});

    const updatedDataFields = { ...defaultValues, uFModuleID: moduleSelected.ufmoduleId };
    return { defaultInputRangeConfig, updatedDataFields };
  };

  const isRackModule = module => module?.integraPacInd || module?.tRack;

  const getDefaultUFConfiguration = selectedConf => {
    const updatedData = {};
    const { skidsPerTrain, modulesPerSkid, onlineTrains } = defaultInputRangeConfig;
    const { onlineUnits, racksPerUnit, modulesPerRack, standByUnits, modulesPerUnit } = selectedConf || {};
    if (onlineUnits === undefined) {
      updatedData.skidsPerTrain = skidsPerTrain.defaultValue;
      updatedData.modulesPerSkid = modulesPerSkid.defaultValue;
      updatedData.onlineTrains = onlineTrains.defaultValue;

      const MODULES_PER_UNIT = parseInt(UFData.skidsPerTrain) * parseInt(UFData.modulesPerSkid);
      updatedData.modulesPerTrain = isRackModule(activeUFModule) ? MODULES_PER_UNIT : 1;

      let TOTAL_UNITS;
      if (UFData.uFBWCEBStandbyOptionID == 1) {
        TOTAL_UNITS = parseInt(UFData.onlineTrains) + parseInt(UFData.redundantTrains);
        updatedData.uFStorageTankOptionID = 2;
      } else if (UFData.uFBWCEBStandbyOptionID == 2) {
        TOTAL_UNITS =
          parseInt(UFData.onlineTrains) + parseInt(UFData.redundantStandbyTrains) + parseInt(UFData.redundantTrains);
        updatedData.uFStorageTankOptionID = 1;
      }
      const TOTAL_MODULES = parseInt(TOTAL_UNITS) * parseInt(UFData.modulesPerTrain);
      updatedData.totalModules = TOTAL_MODULES;
      updatedData.redundantStandbyTrains = "0";
      updatedData.totalTrains = TOTAL_UNITS;
      updatedData.skids = Math.round(TOTAL_UNITS * skidsPerTrain.defaultValue);
    } else if (activeUFModule?.integraPacInd !== undefined || activeUFModule?.track !== undefined) {
      const skidsPerTrainVal = Number(racksPerUnit) || skidsPerTrain.defaultValue;
      updatedData.skidsPerTrain = skidsPerTrainVal;
      updatedData.modulesPerSkid = modulesPerRack || modulesPerSkid.defaultValue;
      updatedData.onlineTrains = onlineUnits || UFData.onlineTrains;
      updatedData.redundantStandbyTrains = standByUnits || "0";
      updatedData.modulesPerTrain = modulesPerUnit || 1;

      const TOTAL_UNITS =
        parseInt(updatedData.onlineTrains) +
        parseInt(updatedData.redundantStandbyTrains) +
        parseInt(UFData.redundantTrains);
      const TOTAL_MODULES = parseInt(TOTAL_UNITS) * parseInt(updatedData.modulesPerTrain);
      updatedData.skids = Math.round(TOTAL_UNITS * skidsPerTrainVal);

      updatedData.totalTrains = TOTAL_UNITS;
      updatedData.totalModules = TOTAL_MODULES;

      if (!isRackModule(activeUFModule)) {
        updatedData.skidsPerTrain = skidsPerTrain.defaultValue;
        updatedData.modulesPerSkid = modulesPerSkid.defaultValue;
      }
    } else {
      Logger.log("getDefaultUFConfiguration - NO MODULE SELECTED", activeUFModule);
    }
    Logger.log("getDefaultUFConfiguration - selectedConf, updatedData: ", selectedConf, updatedData);
    return {
      defaultUFConfig: selectedConf,
      data: updatedData,
    };
  };

  const getConfigForUFFields = (label, waterSubTypeBasedInputConfigs) => {
    let normalRange = [];
    let rangeOnWaterSubType = [];
    if (label == "recycleFlowRate") {
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      normalRange = [
        {
          ...normalRange[0],
          minValue: activeUFModule.cIP_Flow_min,
          maxValue: activeUFModule.cIP_Flow_max,
          defaultValue: convertFromMetric(activeUFModule.cIP_Flow_std, UNIT_TYPES.FLOW),
        },
      ];
    } else if (label == "recycleFlowRate_MiniCIP") {
      // ranges should be same as recycleFlowRate , value should be 0 for inge or isCebOnly
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      let defaultValue = convertFromMetric(activeUFModule.cIP_Flow_std, UNIT_TYPES.FLOW);
      const { isCebOnly } = UF_SPECIAL_FEATURE;
      if (isIngeSelected(UFData.pUFTechnologyID) || +UFData.ufSpecialFeatureID === +isCebOnly) {
        defaultValue = 0;
      }
      normalRange = [
        {
          ...normalRange[0],
          label: DesignStrings.miniCIPRecycleFlowRate,
          minValue: activeUFModule.cIP_Flow_min,
          maxValue: activeUFModule.cIP_Flow_max,
          defaultValue,
        },
      ];
    } else if (label == "miniCIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Mini-CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "mCIP Interval");
    } else if (label == "valveOpenCloseDuration") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Valve Open/Close Action Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "t_valves");
    } else if (label == "oxidantValue_BW") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Backwash Oxidant Concentration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash NaOCl Dose");
    } else if (label == "backwash_design") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Filtration Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash Interval");
    } else if (label == "alkaliOxidantCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Alkaline CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Alkali CEB Interval");
    } else if (label == "acidCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Acid CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Acid CEB Interval");
    } else if (label == "cIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "CIP Interval");
    } else if (label === "maxAirScourPressure") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Average Air Scour Pressure");
      if (isIngeSelected(UFData.pUFTechnologyID) && normalRange?.length > 0) {
        const updatedConfig = { ...normalRange[0], defaultValue: 0 };
        normalRange[0] = updatedConfig;
      }
    } else {
      normalRange = ufInputRangeConfig.filter(config => config.label == UF_FIELDS_MAPPING[label]);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == UF_FIELDS_MAPPING[label]);
    }
    const rangeConfig = rangeOnWaterSubType?.length > 0 ? rangeOnWaterSubType : normalRange;
    return rangeConfig;
  };

  const getUFFieldRangeConfig = (key, inputsConfig, activeUFModule, calculatedData) => {
    if (key == "filtrateFlux") {
      return getConfigForFiltrateFlux(activeUFModule);
    } else if (key == "forwardFlushFlow") {
      const { defaultValue } = calculatedData.filtrateFlux;
      return getConfigForForwardFlushFlow(activeUFModule, defaultValue);
    } else if (key == "airFlow") {
      return getConfigForAirFlow(activeUFModule);
    } else if (key == "bwRinseDrainTop" || key == "bwRinseDrainBottom") {
      return getConfigForBackwashRinse(activeUFModule);
    } else {
      return getConfigForUFFields(key, inputsConfig);
    }
  };

  const getUFFieldsRangeConfig = moduleSelected => {
    const inputsConfig = ufInputRangeConfigByWaterTypeById[waterSubTypeID] || [];
    const result = Object.keys(UFData).reduce((acc, key) => {
      const data = getUFFieldRangeConfig(key, inputsConfig, moduleSelected, acc);
      const result = { [key]: data?.[0] || {} };
      return { ...acc, ...result };
    }, {});
    return result;
  };
  /**
   * Updates the filtrate flux data based on the provided design temperature.
   *
   * This function retrieves the default values for filtrate flux and forward flush flow
   * configurations for the active UF module, then dispatches an action to update the UF store data
   * with these values.
   *
   * @param {number} designTemp - The design temperature to be used for fetching the configurations.
   */
  const updateFiltrateFluxData = designTemp => {
    const getDefaultValue = config => (config && config.length > 0 ? config[0].defaultValue : 0);

    const fluxConfig = getConfigForFiltrateFlux(activeUFModule, designTemp);
    const filtrateFlux = getDefaultValue(fluxConfig);

    const flowConfig = getConfigForForwardFlushFlow(activeUFModule, filtrateFlux);
    const forwardFlushFlow = getDefaultValue(flowConfig);

    dispatch(updateUFStoreData({ data: { filtrateFlux, forwardFlushFlow } }));
  };

  const getUFFieldsDefaultValues = (moduleSelected, fields) => {
    const defaultInputRangeConfig = getUFFieldsRangeConfig(moduleSelected);
    const defaultValues = fields.reduce((acc, key) => {
      const { defaultValue } = defaultInputRangeConfig[key] || {};
      if (defaultValue >= 0) {
        const convertedVal = convertValueForSelectUnit(key, defaultValue);
        acc[key] = convertedVal;
      }
      return acc;
    }, {});
    return defaultValues;
  };

  return {
    getModuleBasedInputRangeConfigs,
    getDefaultUFConfiguration,
    getUFFieldsRangeConfig,
    getConfigForFiltrateFlux,
    getConfigForForwardFlushFlow,
    getConfigForAirFlow,
    updateFiltrateFluxData,
    getUFFieldsDefaultValues,
  };
};
