import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { combineClassNames } from "@utils/appUtils";

import { setSideNavCollapsible, setSideNavOpen } from "@features/menu/SideMenuSlice";

import "./WPSideNav.scss";

export const WPSideNav = ({ children, className, top = 180 }) => {
  const dispatch = useDispatch();
  const { isSideNavOpen, isSideNavCollapsible } = useSelector(state => state.leftpanel);

  const sideNavOverlayRef = useRef();

  // Optimized approach compared to resize event
  const mediaQuery = useMemo(() => window.matchMedia("(max-width:1024px)"), []);

  const [isSmallScreen, setIsSmallScreen] = useState(mediaQuery.matches);

  // cleanup on unmount
  useEffect(() => () => dispatch(setSideNavCollapsible(false)), []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (sideNavOverlayRef.current && !sideNavOverlayRef.current.contains(event.target)) {
        dispatch(setSideNavOpen(false));
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    const handler = event => setIsSmallScreen(event.matches);
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [mediaQuery]);

  const topValue = useMemo(() => (isSideNavOpen ? 60 : top), [isSideNavOpen, top]);

  const sideNavStyles = useMemo(
    () => ({
      top: `${topValue}px`,
      height: `calc(100vh - ${topValue}px)`,
    }),
    [topValue],
  );

  useEffect(() => {
    dispatch(setSideNavCollapsible(isSmallScreen));
  }, [isSmallScreen]);

  if (!isSideNavOpen && isSideNavCollapsible) {
    return null;
  }

  const sideNav = () => (
    <div className={combineClassNames("wp-sidenav-wrapper", className)} style={sideNavStyles}>
      {children && children}
    </div>
  );

  return isSideNavCollapsible ? (
    <div ref={sideNavOverlayRef} className='wp-sidenav-overlay'>
      {sideNav()}
    </div>
  ) : (
    sideNav()
  );
};

export default WPSideNav;
