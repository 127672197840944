import React from "react";

const SendProjectIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.0335 2.55957L22.5526 12.1759L13.0335 21.7923V16.6631C11.0767 16.3398 9.35615 16.4196 7.8405 16.9011C6.15728 17.4358 4.66323 18.4859 3.34694 20.1547L1.61621 22.3489L1.80172 19.5604C2.05947 15.6862 3.52242 12.7413 5.74049 10.7377C7.74546 8.92666 10.3134 7.933 13.0335 7.6723V2.55957ZM14.7669 6.77448V9.31321L13.9358 9.34741C11.24 9.45837 8.7586 10.3473 6.90236 12.024C5.55151 13.2442 4.4966 14.913 3.93709 17.083C4.96486 16.249 6.09026 15.6384 7.3157 15.2491C9.36113 14.5993 11.6215 14.5861 14.076 15.0946L14.7669 15.2378V17.5774L20.1137 12.1759L14.7669 6.77448Z'
      fill='white'
    />
  </svg>
);

export default SendProjectIcon;
