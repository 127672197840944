import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

export const SPECIES_UNIT_MICROGRAM_LITRE = 1;

export const AMBERLITE_62_I_ID = 163;

export const selectedResinList = [
  {
    ixResinID1: 0,
    inert: null,
    ixResinID2: 0,
    ionicFormSelected_ind: false,
    columnNo: 1,
    defaultPackagingSize: 0,
  },
  {
    ixResinID1: 0,
    inert: null,
    ixResinID2: 0,
    ionicFormSelected_ind: true,
    columnNo: 2,
    defaultPackagingSize: 0,
  },
];

export const TECH_UF = "UF";
export const TECH_IX = "IX";
export const TECH_IXSD = "IXSD";
export const TECH_IXOS = "IXOS";
export const TECH_RO = "RO";
export const TECH_CCRO = "CCRO";
export const TECH_ROSC = "ROSC";
export const TECH_IXD = "IXD";
export const TECH_IXN = "IXN";
export const TECH_IXB = "IXB";
export const TECH_IXMB = "IXMB";
export const TECH_IXCP = "IXCP";

export const waterTreatmentProcesses = {
  pretreatement: [TECH_UF, TECH_IXSD, TECH_IXOS],
  bulkdemineralization: [TECH_RO, TECH_CCRO, TECH_ROSC, TECH_IXD],
  trace: [TECH_IXN, TECH_IXB],
  posilishing: [TECH_IXMB, TECH_IXCP],
};

export const FINAL_PARAMETER_LIST = {
  resinType: "final",
  resinId: 0,
  vesselNo: 0,
  resinVolumeAsDelivered: 0,
  vesselDiameter: 0,
  resinBedHeightAsDelivered: 0,
  resinBedStandardHeight: 0,
  resinBedHeightAsExhausted: 0,
  resinBedHeightAsRegenerated: 0,
  inertResinVolume: 0,
  inertBedHeight: 0,
  vesselCylindricalHeight: 0,
  vesselWallThickness: 0,
  pressureDropwithRecomQty: 0,
  resinPackagingSize: 0,
  ixfpaRadioButtonID: 0,
};

export const TRADE_ID = 2;

export const OPERATING_CYCLE_OPTIONS = ["Hours", "Days"];

export const SIO2_MASS_MILLI = 60.08433;

export const SIO2_MASS_MICRO = 60084.33;

export const WATER_BLOCK_ID = 5;

export const BW_SOURCE_IXMB = 1;

export const SAC_SAFETY_FACTOR = "SAC Safety Factor";
export const WAC_SAFETY_FACTOR = "WAC Safety Factor";
export const SBA_SAFETY_FACTOR = "SBA Safety Factor";
export const WBA_SAFETY_FACTOR = "WBA Safety Factor";
export const WAC_OVERRUN = "WAC Overrun";
export const WBA_OVERRUN = "WBA Overrun";
export const REGENERATION_RATIO = "Regeneration Ratio";

export const DEFAULT_REGENERANT_DOSE_VAL3 = 100;

export const WAC_RESIN_ID = 3;
export const WBA_RESIN_ID = 4;
export const REGENERANT_DOSE_ID = 1;
export const REGENERANT_RATIO = 4;

export const RESIN_ID = {
  WAC_RESIN_ID: 3,
  WBA_RESIN_ID: 4,
  SAC_RESIN_ID: 2,
  SBA_RESIN_ID: 1,
  WAC_SAC_TWO_VESSEL_RESIN_ID: 5,
  WBA_SBA_TWO_VESSEL_RESIN_ID: 11,
  WAC_SAC_TWO_CHAMBER_RESIN_ID: 7,
  WBA_SBA_TWO_CHAMBER_RESIN_ID: 13,
  WAC_SAC_SINGLE_CHAMBER_RESIN_ID: 6,
  WBA_SBA_SINGLE_CHAMBER_RESIN_ID: 12,
  SBA_SAC_SINGLE_CHAMBER_RESIN_ID: 9,
  SBA_SAC_TWO_CHAMBER_RESIN_ID: 10,
  INERT_NONE_ID: 0,
};

export const VESSEL_ID = {
  WATER_BLOCK_ID: 5,
  AIR_BLOCK_ID: 4,
  COCURRENT_ID: 0,
  AMBERPACK_ID: 2,
  MB_INTERNAL_ID: 8,
  MB_EXTERNAL_ID: 9,
  UPCORE_ID: 1,
};

export const CATION_ANION_RESIN_ID = {
  MIXED_BED: 9,
};

export const CHEMICAL_NAMES = {
  CHLORINE: "Cl",
  HYDRO_OXIDE: "OH",
  SODIUM: "Na",
  HYDROGEN: "H",
  SULPHATE: "SO4",
};

export const AMBERLITE_600I_Inert_ID = 162;

export const IXD_STRING = {
  CATION: "cation",
  ANION: "anion",
  BACKWASH: "backwash",
  SERVICE_WATER: "serviceWater",
  IX_RESINID_2: "ixResinID2",
  SBA: "SBA",
  SAC: "SAC",
  INERT: "inert",
  IONIC_FORM_SELECTED_IND: "ionicFormSelected_ind",
  VESSEL_WALL_THICKNESS: "vesselWallThickness",
  INERT_BED_HEIGHT: "inertBedHeight",
  TEMPERATURE: "temperature",
  REGENERAT_DOSE: "regenerantDoseVal4",
  AVG_CONDUCTIVITY: "averageConductivityVal",
  END_CONDUCTIVITY: "endpointConductivityVal",
  REGENVELOCITY: "regenerationVelocity",
  DISPLACEMENT_VOL: "displacementVolume",
  FAST_RINSE_VOL: "fatRinseVolume",
  NACL_CYCLE_SOLD: "srNaClCycleSold",
  NACL_CYCLE_REGEN: "srNaClCycleRegen",
  NACL_CYCLE_EXHAUST: "srNaClCycleExhaust",
  OH_CYCLE_SOLD: "srHOhCycleSold",
  OH_CYCLE_REGEN: "srHOhCycleRegen",
  OH_CYCLE_EXHAUST: "srHOhCycleExhaust",
  VESSEL_CYL_HEIGHT: "vesselCylindricalHeight",
  REGENERANT_CONDITION: "Regeneration Conditions",
};
export const SAVE_VESEEL_CYLINDRICAL_HEIGHT = {
  vesselCylindricalHeight0: { isUserInput: false, value: 0, freeBoard: 0, vesselName: "" },
  vesselCylindricalHeight1: { isUserInput: false, value: 0, freeBoard: 0, vesselName: "" },
  vesselCylindricalHeight2: { isUserInput: false, value: 0, freeBoard: 0, vesselName: "" },
  vesselCylindricalHeight3: { isUserInput: false, value: 0, freeBoard: 0, vesselName: "" },
};

export const IXInitializationRanges = {
  operatingCycle_Lenght_txtInDays: {
    min: 3,
    max: 2400,
  },
  operatingCycle_Lenght_txt: {
    min: 0.125,
    max: 100,
  },
  bypassed: {
    min: 0,
    max: 95,
  },
  trains_Online: {
    min: 1,
    max: 20,
  },
  trains_StandBy: {
    min: 0,
    min_train_online: 1,
    max: 5,
  },
};

export const PROPERTIES_TO_CONVERT_SAVING_DATA = [
  { key: "resinVolumeAsDelivered", toUnit: UNITS.CUBIC_METER, fromUnit: SELECTED_UNIT.VOLUME },
  { key: "inertResinVolume", toUnit: UNITS.CUBIC_METER, fromUnit: SELECTED_UNIT.VOLUME },
  { key: "vesselDiameter", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsDelivered", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedStandardHeight", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsRegenerated", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsExhausted", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "inertBedHeight", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "vesselCylindricalHeight", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "vesselWallThickness", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "freeBoard", toUnit: UNITS.millimeter, fromUnit: SELECTED_UNIT.LENGTH },
];

export const PROPERTIES_TO_CONVERT_CALC_ENGINE_DATA = [
  { key: "resinVolumeAsDelivered", toUnit: UNITS.CUBIC_METER, fromUnit: SELECTED_UNIT.VOLUME },
  { key: "inertResinVolume", toUnit: UNITS.CUBIC_METER, fromUnit: SELECTED_UNIT.VOLUME },
  { key: "vesselDiameter", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsDelivered", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedStandardHeight", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsRegenerated", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "resinBedHeightAsExhausted", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "inertBedHeight", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "vesselCylindricalHeight", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
  { key: "vesselWallThickness", toUnit: UNITS.METER, fromUnit: SELECTED_UNIT.LENGTH },
];

export const PROPERTIES_TO_CONVERT_FPA_DATA = [
  { key: "resinVolumeAsDelivered", toUnit: SELECTED_UNIT.VOLUME, fromUnit: UNITS.CUBIC_METER },
  { key: "inertResinVolume", toUnit: SELECTED_UNIT.VOLUME, fromUnit: UNITS.CUBIC_METER },
  { key: "vesselDiameter", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "resinBedHeightAsDelivered", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "resinBedStandardHeight", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "resinBedHeightAsRegenerated", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "resinBedHeightAsExhausted", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "inertBedHeight", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "vesselCylindricalHeight", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "freeBoard", toUnit: SELECTED_UNIT.LENGTH, fromUnit: UNITS.METER },
  { key: "pressureDropwithRecomQty", toUnit: SELECTED_UNIT.PRESSURE, fromUnit: UNITS.bar },
];

export const SPECIES_LABEL_ID = {
  conductivityLbaleID: 2,
  anionSpeciesLabelID: 4,
};

export const REGENERATION_RATIO_RANGE = {
  min: 100,
  max: 10000,
};

// WBA, WAC, SBA, SAC
export const SAFETY_FACTOR_RANGE = {
  min: 0.001,
  max: 2.0,
};

export const OverallProcessType = {
  Demineralization: 7,
  IX_Demin_Polishing: 11,
  RO_Permeate_Polishing: 10,
};

export const CHECMICAL_VALUES_REGENRANT = {
  H2SO4: 2,
};
export const ALERT_MSG = {
  RESIN_ARRANGEMENT_CHANGE:
    "Changes to resin arrangement or regeneration systems will clear any inputs on later screens. Consider adding another case if you want to make a comparison. Do you want to proceed?",
  EFFLUENT_QUALITY_CATION_CHANGE:
    "The Na Average Leakage value is larger than the Endpoint value. Please revise your input.",
  EFFLUENT_QUALITY_ANION_CHANGE:
    "The SiO₂ Average Leakage value is larger than the Endpoint value. Please revise your input.",
  DOSE_FRAC_VALIDATION: "The sum of dose fractions should be 100%",
  CO2_PRESSURE_VALIDATION: (min, max) =>
    `The IX Degasification Effluent Partial Pressure is not correctly specified. It should be between ${min} and ${max}`,
};

export const CONDUCTIVITY_INIT_VAL = {
  vesselCoCurrentAvg: 1.08,
  vesselCoCurrentEnd: 2.15,
  vesselOthersAvg: 0.22,
  vesselOthersEnd: 0.43,
};

export const IXD_DESIGN_FPA = {
  EVALUATE_DESIGN: 1,
  RETROFIT_DESIGN: 2,
};

export const EffluentQulatiyRanges = {
  Average: {
    min: 0.46,
    max: 4600000,
  },
  EndPoint: {
    min: 0.46,
    max: 4600000,
  },
};

// Below conversion factors are used in FPA screen calculations
export const CONVERSION_FACTORS = {
  cm: 0.01,
  in: 0.0254,
  mm: 0.001,
};
export const REVERSE_CONVERSION_FACTORS = {
  cm: 100,
  in: 39.3700787402,
  mm: 1000,
};

export const SERVICE_WATER = {
  CATION_BW_FW: 0,
  CATION_BW_DW: 2,
  ANION_BW_DW: 1,
};

export const SPECIES_UNIT = {
  MICROGRAM_LITRE: 1,
  MILIEQUIVALENT_PER_LITER: 2,
  MILIGRAM_PER_LITER: 3,
  PPMV: 4,
};

export const OVERRUN_COMPUTATION = {
  MANNUAL: 0,
  AUTOMATIC: 1,
};

export const EquipmentDefaults = {
  pdExtPiping: 0.1,
  pdIntDistributor: 0.05,
  effluentPressure: 1,
  tankTemperature: 25,
};

// Constants for IX regeneration dose IDs and safety factors(Product Quality Page)
export const IX_REGEN_DOSE_ID = {
  sacSafetyFactor: 5,
  sbaSafetyFactor: 6,
  wacSafetyFactor: 7,
  wbaSafetyFactor: 8,
  wbaOverRun: 9,
  wacOverRun: 11,
};

/**
 * CO2 partial pressure. No unit conversion is required for minPressure and maxPressure as
 * numerically they are the same value, but depending on where you are,
 * the label should reflect user selection back on the units page.
 */
export const CO2_PARTIAL_PRESSURE = {
  minimumPressure: 1,
  maximumPressure: 1000000,
};
