import React, { useMemo } from "react";
import styled from "styled-components";

import CustomTooltip from "@common/styles/components/tooltip/CustomTooltip";

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const IconButton = ({
  tooltip,
  placement,
  children,
  onClick,
  id = "icon-btn",
  name,
  className,
  disabled,
  classNameTooltip,
}) => {
  const tooltipMsg = useMemo(() => (disabled ? "" : tooltip), [disabled, tooltip]);

  const handleClick = event => {
    if (onClick) onClick({ target: { id, name } });
    event.stopPropagation();
  };

  return (
    <CustomTooltip text={tooltipMsg} placement={placement} className={classNameTooltip}>
      <StyledButton
        id={id}
        name={name || id}
        className={className}
        data-tip={tooltipMsg}
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    </CustomTooltip>
  );
};

export default IconButton;
