import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import useUFConfig from "@hooks/useUFConfig";
import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { getPumpValues } from "../UFDiagramsUtils/UFDiagramHelper";

const UFDiagramCIPPumpDetails = () => {
  const { unitConversionByName, convertFromMetric } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [volumData, setVolumData] = useState({});

  const { ufMaxPumpPressureValues } = useUFConfig();

  useEffect(() => {
    const pressureValue = convertFromMetric(ufMaxPumpPressureValues.cip, UNIT_TYPES.PRESSURE);
    const data = getPumpValues(unitConfig, calcEngineData, "flow_CIP_Pump", pressureValue, unitConversionByName);
    setVolumData(data);
  }, [unitConfig.selectedUnits, calcEngineData, getPumpValues, ufMaxPumpPressureValues.cip]);

  return (
    <>
      <BoldTextElement x='1800' y='2800'>
        CIP Pump
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x='1800' y='2940'>
          {volumData.value}
        </tspan>
        <tspan x='1800' y='3080'>
          {volumData.pressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramCIPPumpDetails;
