import { createSlice } from "@reduxjs/toolkit";

import { PROJECT_VIEWS } from "@constants/global.constants";

const initialState = {
  projectID: 0,
  projectActiveView: PROJECT_VIEWS.DESIGN,
  caseName: "Case 1",
  projectName: null,
  treatmentName: null,
  treatmentObjID: null,
  Tchnology: [],
  case: [],
  activeCase: {
    caseID: 0,
    caseName: "",
  },
  projectConfig: {
    unitConfig: {
      defaultValues: [],
      selectedUnits: [],
      selectedUnitType: 0,
    },
    currencyConfig: {
      defaultValues: [],
      selectedCurrency: {
        currencyUnit: "$",
        currencyID: 1,
        currencyName: "US Dollar($)",
        isDefault: true,
        currencyValue: "1",
      },
    },
    pumpCofig: {
      pupmList: [],
    },
    caseConfig: {
      caseList: [],
      caseNameList: [],
    },
  },
  data: {
    projectID: 0,
    caseId: 0,
    projectName: "",
    Tchnology: "",
    isImported: false,
  },
  projectData: {},
  projectChemicalCosts: {},
  isUserInsideProjectFlag: false,
};

export const ProjectInfoSlice = createSlice({
  name: "ProjectInfo",
  initialState,
  reducers: {
    updateProjectInfo: (state, action) => {
      state.data = action.payload;
    },

    updateActiveCase: (state, action) => {
      state.activeCase = action.payload;
    },
    updateUnitConfig: (state, action) => {
      const incomingUnit = action.payload;
      const selectedUnitType = incomingUnit[0].uomSelectType;
      const isUnitNew = incomingUnit[0].unitKey.filter(item => item.isSelected);
      const selectedUnits = [];
      if (isUnitNew.length > 0) {
        incomingUnit.map(item => {
          const unitTag = item.unitKey.filter(item => item.isSelected);
          if (unitTag.length > 0) {
            selectedUnits[item.uomTypeID] = unitTag[0].uomName;
          } else {
            selectedUnits[item.uomTypeID] = item.unitKey[0].uomName;
          }
        });
      }

      state.projectConfig.unitConfig.selectedUnits = selectedUnits;
      state.projectConfig.unitConfig.defaultValues = incomingUnit;
      state.projectConfig.unitConfig.selectedUnitType = selectedUnitType;
    },
    updatePumpList: (state, action) => {
      state.projectConfig.pumpCofig.pupmList = action.payload;
    },
    updateCaseConfig: (state, action) => {
      state.projectConfig.caseConfig.caseList = action.payload;
      state.projectConfig.caseConfig.caseNameList = action.payload.map(item => item.caseName);
    },
    updateProjectCurrency: (state, action) => {
      const selectdetCurrency = action.payload.find(item => item.isDefault);
      const currencyName = selectdetCurrency.currencyName.replace("(", " ").replace(")", "").split(" ");
      const symbole = currencyName[currencyName.length - 1];
      state.projectConfig.currencyConfig.selectedCurrency = {
        ...action.payload.find(item => item.isDefault),
        currencyUnit: symbole,
      };
    },
    updateProjectInfoState: (state, { payload: { type, data } }) => {
      state[type] = data;
    },
    resetProjectInfo: (state, { payload }) => {
      state = { ...initialState };
      const currentCase = payload?.activeCase;
      if (currentCase !== undefined) {
        state.activeCase = currentCase;
      } else {
        state.activeCase = {
          caseID: 0,
          caseName: "",
        };
      }
    },
    resetActiveCase: state => {
      state.activeCase = {
        caseID: 0,
        caseName: "",
      };
    },
    updateInsideProjectFlag: (state, action) => {
      state.isUserInsideProjectFlag = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updatePumpList,
  resetProjectInfo,
  updateUnitConfig,
  updateCaseConfig,
  updateActiveCase,
  updateProjectInfo,
  updateProjectCurrency,
  updateProjectInfoState,
  resetActiveCase,
  updateInsideProjectFlag,
} = ProjectInfoSlice.actions;

export default ProjectInfoSlice.reducer;
