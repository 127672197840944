import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import useUFConfig from "@hooks/useUFConfig";
import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { formatFlowValue, formatPressureValue } from "../UFDiagramsUtils/UFDiagramHelper";

const FLOW_METRIC_UNIT = "m³/h";

const UFDiagramBWCEBPumpDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName, convertFromMetric } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { ufMaxPumpPressureValues } = useUFConfig();

  const [pumpData, setPumpData] = useState({});

  const getFiltrate = filtrate => {
    const selectedFlowUnit = unitConfig.selectedUnits[1];
    let value = (calcEngineData && calcEngineData[filtrate]) || 0;

    if (value && selectedFlowUnit !== FLOW_METRIC_UNIT) {
      value = unitConversionByName(value, selectedFlowUnit, FLOW_METRIC_UNIT);
    }
    return formatFlowValue(value, selectedFlowUnit);
  };

  useEffect(() => {
    const data = {
      bw: `BW ${getFiltrate("flow_BW_Pump")}`,
      ceb: `CEB ${getFiltrate("flow_CEB_Pump")}`,
    };
    setPumpData(data);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const bwMaxPressure = useMemo(() => {
    const pressureUnit = unitConfig.selectedUnits[3];
    const maxPressureVal = convertFromMetric(ufMaxPumpPressureValues.bw, UNIT_TYPES.PRESSURE);
    return formatPressureValue(maxPressureVal, pressureUnit);
  }, [ufMaxPumpPressureValues.bw]);

  const xyPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5650, y: 3250 } : { x: 5650, y: 2850 }) : { x: 6000, y: 3250 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement>
        <tspan {...xyPosition}>Backwash Pump</tspan>
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          {pumpData.bw}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {pumpData.ceb}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 420}>
          {bwMaxPressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramBWCEBPumpDetails;
