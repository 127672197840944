import { CircleLoader } from "react-spinners";

import LoaderStyled from "@common/styles/LoaderStyled";
const Loader = () => (
  <>
    <LoaderStyled className='sweet-loading' data-test-id='wp-loader'>
      <CircleLoader color={"#007672"} loading={true} />
      <p>WAVE PRO</p>
    </LoaderStyled>
  </>
);

export default Loader;
