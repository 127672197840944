import { useMemo } from "react";

import { loadExternalScript } from "@utils/appUtils";

import { WPFullScreenLoader } from "@components/WPFullScreenLoader";

import MinervaIntegration from "@features/minervaIntegration/minerva";

import Routing from "./Routing";

const App = () => {
  const trustArcCookieURL = process.env.REACT_APP_TRUSTARC_CONSENT_URL;
  if (trustArcCookieURL) {
    loadExternalScript(trustArcCookieURL);
  }

  const isMinervaEnabled = useMemo(() => process.env.REACT_APP_MINERVA_ENABLED === "true", []);

  return (
    <>
      <Routing />
      <WPFullScreenLoader />
      {isMinervaEnabled && <MinervaIntegration />}
    </>
  );
};

export default App;
