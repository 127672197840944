import { EXTERNAL_URLS } from "@constants/api.urls";

import { FooterStrings } from "@utils/StringConstants";

import { InstagramIcon, LinkedInIcon, YoutubeIcon } from "@components/SVGs";

// Social media links with their respective icons
export const SOCIAL_MEDIA_LINKS = [
  { href: EXTERNAL_URLS.linkedinURL, Icon: LinkedInIcon },
  { href: EXTERNAL_URLS.instagramURl, Icon: InstagramIcon },
  { href: EXTERNAL_URLS.youtubeURL, Icon: YoutubeIcon },
];

export const FOOTER_LINKS = [
  { href: EXTERNAL_URLS.dupontUrl, text: FooterStrings.dupontCom },
  { href: EXTERNAL_URLS.privacyUrl, text: FooterStrings.privacy },
  { href: EXTERNAL_URLS.termsConditions, text: FooterStrings.legalNoticeUsageTerms },
  { href: EXTERNAL_URLS.a11yUrl, text: FooterStrings.ally },
];
