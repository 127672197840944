import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import TextElementWithArrow from "../UFDiagramsUtils/TextElementWithArrow";
import { CEB_DETAILS_LIST } from "../UFDiagramsUtils/UFDiagramConstants";
import { getCEBChemicalDetails } from "../UFDiagramsUtils/UFDiagramHelper";

const UFDiagramBWCEBChemDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();

  const {
    projectConfig: { unitConfig },
    projectChemicalCosts,
  } = useSelector(state => state.projectInfo);

  const { data, calcEngineData } = useSelector(state => state.UFStore);
  const [cebChemicals, setCEBChemicals] = useState({});

  const convertUnitCallback = useCallback(
    value => {
      const volumnLiter = UNITS.liter;
      const selectedUnit = unitConfig.selectedUnits[11];
      const targetUnit = selectedUnit !== UNITS.gallon ? volumnLiter : selectedUnit;
      if (value && targetUnit !== volumnLiter) {
        value = unitConversionByName(value, targetUnit, volumnLiter);
      }
      value = convertUptoDigits(value, 1);
      return `${value} ${targetUnit}/${UNITS.hour}`;
    },
    [unitConfig.selectedUnits],
  );

  useEffect(() => {
    const cebChemicalDetails = getCEBChemicalDetails(
      data,
      calcEngineData,
      projectChemicalCosts?.chemicalListById,
      convertUnitCallback,
    );
    setCEBChemicals(cebChemicalDetails);
  }, [data, calcEngineData, unitConfig.selectedUnits]);

  const getXY = (x, y) => (isInge ? (isCIP ? { x: x - 100, y: y - 120 } : { x: x - 100, y: y - 520 }) : { x, y });
  const getArrowPath = ({ arrowDuPontCIP, arrowIngeWithCIP, arrowIngeWithoutCIP }) =>
    isInge ? (isCIP ? arrowIngeWithCIP : arrowIngeWithoutCIP) : arrowDuPontCIP;

  return (
    <>
      {CEB_DETAILS_LIST.map(item => {
        const { key, x, y } = item;
        const text = cebChemicals[key];
        return text && <TextElementWithArrow key={key} {...getXY(x, y)} d={getArrowPath(item)} text={text} />;
      })}
    </>
  );
};

export default UFDiagramBWCEBChemDetails;
