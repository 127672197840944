import React from "react";

const ProjectLockCollaboratorIcon = () => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='32' cy='32' r='31.5' stroke='#007672' />
    <mask id='path-2-inside-1_7838_21004' fill='white'>
      <path d='M25.4189 23C25.4189 21.3431 26.7621 20 28.4189 20H36.3544C38.0113 20 39.3544 21.3431 39.3544 23V30.0645H25.4189V23Z' />
    </mask>
    <path
      d='M24.4189 23C24.4189 20.7909 26.2098 19 28.4189 19H36.3544C38.5636 19 40.3544 20.7909 40.3544 23H38.3544C38.3544 21.8954 37.459 21 36.3544 21H28.4189C27.3144 21 26.4189 21.8954 26.4189 23H24.4189ZM39.3544 30.0645H25.4189H39.3544ZM24.4189 30.0645V23C24.4189 20.7909 26.2098 19 28.4189 19V21C27.3144 21 26.4189 21.8954 26.4189 23V30.0645H24.4189ZM36.3544 19C38.5636 19 40.3544 20.7909 40.3544 23V30.0645H38.3544V23C38.3544 21.8954 37.459 21 36.3544 21V19Z'
      fill='#007672'
      mask='url(#path-2-inside-1_7838_21004)'
    />
    <rect x='20.5' y='30.5645' width='23' height='12.9355' rx='1.5' stroke='#007672' />
    <path
      d='M32.0002 37.5323C32.9177 37.5323 33.6614 36.7885 33.6614 35.871C33.6614 34.9535 32.9177 34.2097 32.0002 34.2097C31.0827 34.2097 30.3389 34.9535 30.3389 35.871C30.3389 36.7885 31.0827 37.5323 32.0002 37.5323Z'
      stroke='#007672'
    />
    <line x1='32.1133' y1='37.0322' x2='32.1133' y2='40.129' stroke='#007672' />
  </svg>
);

export default ProjectLockCollaboratorIcon;
